// Layout.js
import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <main>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
