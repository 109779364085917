import React from "react";
import "./terms.css";
import { useNavigate } from "react-router-dom";

const TermsOfUse = () => {
  const navigate = useNavigate();
  const termsData = [
    {
      title: "1. Use of the Site",
      content: `
      a. <strong>Eligibility:</strong> You must be at least 18 years old and have the legal capacity to enter into this Agreement.<br>
      b. <strong>License:</strong> Subject to your compliance with this Agreement, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Site for its intended purpose.
    `,
    },
    {
      title: "2. User Accounts",
      content: `
      a. <strong>Account Creation:</strong> In order to access certain features of the Site, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.<br>
      b. <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
    `,
    },
    {
      title: "3. Prohibited Conduct",
      content: `
      You agree not to:<br>
      a. Use the Site for any illegal purpose or in violation of any applicable laws or regulations.<br>
      b. Use the Site to engage in any conduct that infringes upon or violates the rights of others.<br>
      c. Use the Site to transmit or distribute any viruses, worms, or other malicious code.<br>
      d. Use the Site to collect or harvest any information from other users.<br>
      e. Interfere with or disrupt the operation of the Site or servers or networks connected to the Site.<br>
      f. Attempt to gain unauthorized access to the Site or any accounts, computer systems, or networks connected to the Site.
    `,
    },
    {
      title: "4. Intellectual Property",
      content: `
      a. <strong>Ownership:</strong> The Site and all content, features, and functionality available through the Site are owned by us or our licensors and are protected by copyright, trademark, and other intellectual property laws.<br>
      b. <strong>Limited License:</strong> Subject to your compliance with this Agreement, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Site for its intended purpose.<br>
      c. <strong>Restrictions:</strong> You may not modify, reproduce, distribute, or create derivative works based on the Site or any content available through the Site without our prior written consent.
    `,
    },
    {
      title: "5. Third-Party Links",
      content: `
      The Site may contain links to third-party websites or resources. We are not responsible for the content, accuracy, or opinions expressed on such websites, and we do not investigate, monitor, or check such websites for accuracy or completeness. Your use of third-party websites is at your own risk.
    `,
    },
    {
      title: "6. Disclaimer of Warranties",
      content: `
      THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SITE OR ANY CONTENT AVAILABLE THROUGH THE SITE.
    `,
    },
    {
      title: "7. Limitation of Liability",
      content: `
      IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY CONTENT AVAILABLE THROUGH THE SITE.
    `,
    },
    {
      title: "8. Indemnification",
      content: `
      You agree to indemnify, defend, and hold harmless [Your Mortgage Website] and its officers, directors, employees, agents, and affiliates from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with your use of the Site or any violation of this Agreement.
    `,
    },
    {
      title: "9. Modifications to the Agreement",
      content: `
      We reserve the right to modify or update this Agreement at any time by posting the revised Agreement on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the revised Agreement.
    `,
    },
    {
      title: "10. Governing Law",
      content: `
      This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
    `,
    },
    {
      title: "11. Dispute Resolution",
      content: `
      Any dispute arising out of or in connection with this Agreement shall be resolved exclusively through arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in [Your Jurisdiction], and the arbitrator's decision shall be final and binding.
    `,
    },
    {
      title: "12. Contact Us",
      content: `
      If you have any questions about this Agreement, please contact us at Email: info@fettifi.com<br>Office: 424-675-6295.<br>
      
    `,
    },
  ];

  return (
    <div>
      <div className="heroSection-terms">
        <h2
          style={{
            fontSize: "44px",
            fontWeight: "bolder",
            textAlign: "center",
            lineHeight: "40px",
          }}
        >
          Terms of Use
        </h2>
        <p>
          Welcome to Fetti Financial Services LLC! This Terms of Use Agreement
          (the "Agreement") is a legally binding contract between you and [Your
          Mortgage Website] ("we," "us," or "our") governing your use of our
          website, including all content, features, and functionality available
          through www.fettifi.com.
        </p>
        <p>
          Please read this Agreement carefully before accessing or using the
          Site. By accessing or using the Site, you agree to be bound by this
          Agreement and our Privacy Policy. If you do not agree to all the terms
          and conditions of this Agreement, then you may not access the Site or
          use any services offered through the Site.
        </p>
        <div className="terms-container">
          {termsData.map((term, index) => (
            <div key={index} className="termsSection">
              <h3>{term.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: term.content }}></p>
            </div>
          ))}
        </div>
        <p style={{ fontWeight: "600" }}>
          By accessing or using the Site, you acknowledge that you have read,
          understood, and agree to be bound by this Agreement. If you do not
          agree to all the terms and conditions of this Agreement, then you may
          not access the Site or use any services offered through the Site.
        </p>
        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    </div>
  );
};

export default TermsOfUse;
