import React, { useEffect, useState } from "react";
import axios from "axios";
import "./chatbot.css";

// Import FontAwesome icon
// import { FaPaperPlane } from "react-icons/fa";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [messagesDataLoading, setMessagesDataLoading] = useState(false);
  const [chatreplyLoading, setchatreplyLoading] = useState(false);
  const [inputValue, setinputValue] = useState("");

  const getChats = async () => {
    try {
      setMessagesDataLoading(true);
      const response = await axios.get(
        "https://fetti-backend.vercel.app/chatwithaidata"
      );
      console.log(response.data.messages);
      setMessages(response.data.messages);
      setMessagesDataLoading(false);
    } catch (error) {
      console.log("Error while getting messages", error);
      setMessagesDataLoading(false);
    }
  };
  const chatWithAi = async () => {
    try {
      setMessages([...messages, { role: "User", content: inputValue }]);
      setchatreplyLoading(true);
      const response = await axios.post(
        "https://fetti-backend.vercel.app/chatwithai",
        {
          userQuery: inputValue,
        }
      );
      console.log(response.data.messages);
      setMessages(response.data.messages);
      //   setMessagesDataLoading(false);
      setchatreplyLoading(false);
      setinputValue("");
    } catch (error) {
      console.log("Error while getting messages", error);
      //   setMessagesDataLoading(false);
      setchatreplyLoading(false);
    }
  };

  useEffect(() => {
    getChats();
  }, []);

  return (
    <div className="chatbot-container">
      {messagesDataLoading ? (
        <h4 style={{ textAlign: "center", color: "slateblue" }}>
          Fetching messages...
        </h4>
      ) : (
        messages.length === 0 && (
          <h4 style={{ textAlign: "center", color: "slateblue" }}>
            No messages available!
          </h4>
        )
      )}
      <div
        className="chat-msgs-container"
        // style={{ overflowY: "scroll", height: "70%" }}
      >
        {messages.length > 0 &&
          messages.map((m, index) => (
            <div
              key={index}
              style={{
                // textAlign: m.role === "User" ? "left" : "right",
                // margin: "20px auto",

                // marginLeft: m.role === "User" ? 70 : "30%",
                backgroundColor: m.role === "User" ? "#fff" : "#efeeef",
                padding: "5px",
                // borderRadius: "5px",
                // marginBottom: "10px",
                position: "relative",
                // width: "80%",
                margin: "0px auto",
                display: "flex",
              }}
            >
              <p
                style={{
                  margin: "0",
                  fontSize: "12px",
                  // position: "absolute",
                  // top: "-5px",
                  width: "5%",
                  marginLeft: "20px",
                }}
              >
                {m.role === "User" ? "You" : "Bot"}
              </p>
              <p
                style={{
                  margin: "10",
                  fontSize: "15px",
                  width: "90%",
                  textWrap: "wrap",
                }}
              >
                {m.content}
              </p>
            </div>
          ))}
      </div>

      <div className="chat-input-container">
        <input
          type="text"
          placeholder="Enter a message..."
          className="chat-input"
          value={inputValue}
          onChange={(e) => setinputValue(e.target.value)}
          disabled={chatreplyLoading}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              chatWithAi();
            }
          }}
        />
        {chatreplyLoading ? (
          <h5 className="send-icon" style={{ fontSize: "10px" }}>
            Getting reply...
          </h5>
        ) : (
          <i class="fa fa-paper-plane send-icon" onClick={chatWithAi}></i>
        )}
      </div>
    </div>
  );
};

export default Chatbot;
