import React from "react";
import Layout from "../../Components/Layout";

export const FAQ = () => {
  return (
    <Layout>
      <div>
        <section
          data-vc-parallax="1.2"
          className="wpb_row vc_row-fluid vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving"
          style={{
          backgroundImage: `url("https://t4.ftcdn.net/jpg/04/95/28/65/360_F_495286577_rpsT2Shmr6g81hOhGXALhxWOfx1vOQBa.jpg")`,
        }}
        >
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1612566657907">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "60px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                      className="vc_custom_heading"
                    >
                      Frequently Asked Questions
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="vc_btn3-container vc_btn3-center">
                      <a
                        className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                        href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                        title="Apply For a Loan"
                      >
                        APPLY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="vc_parallax-inner skrollable skrollable-between"
            data-bottom-top="top: -20%;"
            data-top-bottom="top: 0%;"
            style={{ height: "120%", top: "-10.9877%" }}
          />
        </section>
        <section className="wpb_row vc_row-fluid vc_custom_1614376378722">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div
                    className="wpb_wrapper "
                    style={{ backgroundColor: "#ffffff" }}
                  >
                    <div
                      className="vc_tta-container"
                      data-vc-action="collapseAll"
                    >
                      <div className="vc_general vc_tta vc_tta-accordion vc_tta-color-white vc_tta-style-classic vc_tta-shape-rounded vc_tta-o-shape-group vc_tta-controls-align-left vc_tta-o-all-clickable vc_custom_1482227862191">
                        <div className="vc_tta-panels-container">
                          <div className="vc_tta-panels">
                            <div
                              className="vc_tta-panel vc_active"
                              id="1482227556236-93210f6b-4694"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmZmuUZJNom2ZtY5RtZHFmZZeWmprIj2mYnZc"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      Is Fetti’s Financial Services a bank?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1607547144457">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      No – we are not a bank or financial
                                      lender. Instead, we connect you with the
                                      best loan rates and vetted lending
                                      options. We’re here to streamline and
                                      speed up the fragmented lending process.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1482227556269-8e604c25-4720"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmZmuUZJNom2ZtY5dwZHCYaZaal5abj2mZlpM"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      So… are you brokers?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1607547189165">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      Not exactly. We’ve built a vetted network
                                      of more than 300 banks and private
                                      lenders, so you can access best-in-class
                                      financial products in one place. At times,
                                      we may act as a broker to facilitate the
                                      best funding path, but our goal is to
                                      connect you directly with a lender who can
                                      meet your needs.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1482227640472-2c115c9c-a06c"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmZmuUZJNonGVnZZhpZGqWZJebl53Jj5aSmsY"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      Is Helpwithmyloan really free?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1607547213509">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      We never charge any upfront fees, so the
                                      application process is 100% free, whether
                                      you get funded or not.
                                    </p>
                                    <p className="p1">
                                      In most cases, we’re paid directly by the
                                      lender at closing. However, every loan is
                                      unique, and some lenders have different
                                      policies. We’ll keep you informed every
                                      step of the way with transparent
                                      information and unbiased loan
                                      recommendations.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1482227667835-d817629f-6a40"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmZmuUZJNonGduaZRsZJxrZJ2cZp3Mj2vDmJM"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      Will this application affect my credit
                                      score?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1607547249367">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      No – our application will <i>not</i>{" "}
                                      affect your credit score. You don’t have
                                      to do a ‘hard credit pull’ to review
                                      lending options, so Helpwithmyloan won’t
                                      show up as an inquiry.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1607547793166-6473792e-95e1"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmaGOZZ5VonWpqYpdtZG5napmdbZbLj26XyZQ"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      What types of loans can you help with?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      Helpwithmyloan is a full spectrum lending
                                      platform, including commercial loans,
                                      business loans, residential loans,
                                      personal loans, and economic injury
                                      disaster loan assistance (EIDL). No matter
                                      what you’re looking for, we’ll help you
                                      make the right financial decision. Compare
                                      top options to get the best rate.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1607547851395-20802c81-2bca"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmaGOZZ5VonmZoZJpsZGpja5aYl5yXj2fEx8Q"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      How long does the process take?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      Fill out our quick questionnaire and get
                                      matched with a lender just 24 hours! We
                                      only collect the necessary information for
                                      the loan request, plus your contact
                                      information. Our industry-leading
                                      automation software will connect you with
                                      the best loan rates from top lenders. Best
                                      of all, you won’t get any spam or
                                      telemarketing calls.
                                    </p>
                                    <p className="p1">
                                      Since 2017, we’ve achieved a 95% loan
                                      approval rating (almost double the
                                      industry standard) and most loans close
                                      within 10 to 60 days.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1607547882057-d367be78-3744"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmaGOZZ5VonmlpYZZuZJxmaZ3ImZuej2iZmJc"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      Can I talk with a real person?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      Of course! Helpwithmyloan combines speed
                                      with exemplary service. Our software will
                                      match you with potential loan options, and
                                      then you’ll get a call from one of our
                                      in-house loan specialists. We’ll walk you
                                      through your options, provide expert
                                      guidance, and explain everything in
                                      easy-to-understand terms.{" "}
                                      <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGhq8aYpmDXpZA">
                                        <span className="s1">Click here</span>
                                      </a>{" "}
                                      to get in touch.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="vc_tta-panel"
                              id="1607547929047-d67f2ea2-3b86"
                              data-vc-content=".vc_tta-panel-body"
                            >
                              <div className="vc_tta-panel-heading">
                                <h4 className="vc_tta-panel-title vc_tta-controls-icon-position-left">
                                  <a
                                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZohmaGOZZ5Von2NwYZVuZJxpasyYmcWYj2jEnJk"
                                    data-vc-accordion
                                    data-vc-container=".vc_tta-container"
                                  >
                                    <span className="vc_tta-title-text">
                                      How does Helpwithmyloan keep my
                                      information secure?
                                    </span>
                                    <i className="vc_tta-controls-icon vc_tta-controls-icon-plus" />
                                  </a>
                                </h4>
                              </div>
                              <div className="vc_tta-panel-body">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <p className="p1">
                                      Your security is our top priority – We
                                      only collect the detailed information for
                                      the loan request plus your contact
                                      information. Get matched for loan options
                                      without compromising your security. Our
                                      top-of-the-line automation software is
                                      always getting better and we encrypt all
                                      of our data with industry best practices.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wpb_row vc_row-fluid vc_custom_1618090006122 vc_row-has-fill">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                      className="vc_custom_heading"
                    >
                      Still Have Questions? Let’s Talk.
                    </h1>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1618090111379 max-850">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          <span style={{ color: "#ffffff" }}>
                            We’re eager to help you find the right lending
                            options to meet your needs. Connect with a real
                            person for one-on-one loan advice and support. Or
                            start by filling out our fast, free questionnaire to
                            apply for a loan and match up with our finance
                            partners.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid textaligncenter vc_custom_1618090174945">
                      <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-offset-3">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1618089956360">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-turquoise"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                                title
                              >
                                APPLY NOW
                              </a>
                            </div>
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1618089964417">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZg"
                                title
                              >
                                FAQ
                              </a>
                            </div>
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1618089971861">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGhq8aYpmDXpZA"
                                title
                              >
                                CONTACT US
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
