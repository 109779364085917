import React from "react";
import Layout from "../../Components/Layout";

export const AboutHWML = () => {
  return (
    <Layout>
      <section className="wpb_row vc_row-fluid backgroundtop vc_row-has-fill"  style={{
          backgroundImage: `url("https://t4.ftcdn.net/jpg/04/95/28/65/360_F_495286577_rpsT2Shmr6g81hOhGXALhxWOfx1vOQBa.jpg")`,
        }}>
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1615874147147">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "60px",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    className="vc_custom_heading"
                  >
                    About Us
                  </h1>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_btn3-container vc_btn3-center">
                    <a
                      className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                      href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpdhk"
                      title="Apply For a Loan"
                    >
                      APPLY NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row vc_row-fluid vc_custom_1612813601521 vc_row-has-fill">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner ">
                <div
                  className="wpb_wrapper "
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1482486413093">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner vc_custom_1482117949347">
                        <div className="wpb_wrapper">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>
                                Our goal is to help you reach yours.{" "}
                                <strong>Fetti’s Financial Services</strong> was founded in
                                2017 to transform the industry and make
                                traditional funding more accessible. In the
                                first three years alone, we built a network of{" "}
                                <strong>300+ vetted finance partners</strong>{" "}
                                and secured more than{" "}
                                <strong>$850 million for our users.</strong>
                              </p>
                              <p>
                                Chris Karageuzian and Jack Artinian, HWML co-
                                founders, met in the banking world and bonded
                                over dreams of something better. With years of
                                high-level experience in banking, lending, and
                                business financing, both leaders knew the ins
                                and outs of the industry. HWML puts that insight
                                to work for you with innovative solutions and
                                cutting-edge technology.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section className="wpb_row vc_row-fluid vc_custom_1615873518313 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-3">
                <div className="vc_column-inner vc_custom_1615873557787">
                  <div className="wpb_wrapper ">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaV0KTX0sKcy6GY05iXkpZkmmaeZWWk154"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-9">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#3c4d6b",
                        textAlign: "left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontStyle: "normal",
                      }}
                      className="vc_custom_heading"
                    >
                      Vetted, Best-in-Class Financial Products
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          HWML is an independent loan search engine. Our simple
                          application will unlock the full spectrum of lending
                          options from <strong>300+ vetted partners</strong>,
                          including traditional banks. Now you don’t have to
                          settle for limited options from a single lender.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wpb_row vc_row-fluid vc_custom_1615873532771 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-3">
                <div className="vc_column-inner vc_custom_1615873562534">
                  <div className="wpb_wrapper ">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaV0KTX0sKX0qOj3pRjbJJlkWKWX6qnyA"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-9">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#3c4d6b",
                        textAlign: "left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontStyle: "normal",
                      }}
                      className="vc_custom_heading"
                    >
                      Apply, Connect &amp; Get Funding Faster
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          HWML streamlines the process with automated loan
                          matching and underwriting technology. Connect with and
                          compare all of your finance options in a single
                          application. Most of our{" "}
                          <strong>loans close within 10 to 60 days!</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wpb_row vc_row-fluid vc_custom_1615873523052 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-3">
                <div className="vc_column-inner vc_custom_1615873568194">
                  <div className="wpb_wrapper ">
                    <div className="wpb_single_image wpb_content_element vc_align_center">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaV0KTX0sKJx5SpyJ2RZZJjkmKeY2Wk154"
                            className="vc_single_image-img attachment-full"
                            alt=""
                            loading="lazy"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-9">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#3c4d6b",
                        textAlign: "left",
                        fontFamily: "Lato",
                        fontWeight: 700,
                        fontStyle: "normal",
                      }}
                      className="vc_custom_heading"
                    >
                      Free and Private Loan Search
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Beyond ‘no obligation’ – HWML is a ‘no impact’ loan
                          search. Unlike other finance platforms, we only
                          collect the detailed information for the loan request
                          plus your contact information, and you won’t receive
                          any spam calls. Plus, there are no fees or hard credit
                          pulls.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section
        id="your-advisors"
        className="wpb_row vc_row-fluid vc_custom_1612894515439 vc_row-has-fill"
      >
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1518485565658">
                <div className="wpb_wrapper ">
                  <h1
                    className="vc_custom_1612894520616"
                    style={{ fontSize: "46px", textAlign: "center" }}
                  >
                    {" "}
                    Meet Your Advisors
                  </h1>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1615873638833">
                    <div className="wpb_column vc_column_container vc_col-sm-4">
                      <div className="vc_column-inner vc_custom_1615873602294">
                        <div className="wpb_wrapper">
                          <div className="wpb_single_image wpb_content_element vc_align_center">
                            <figure className="wpb_wrapper vc_figure">
                              <a
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqseUpJSYmqXLpY6cx6OYmMassaGUoZU"
                                target="_self"
                                className="vc_single_image-wrapper   vc_box_border_grey"
                              >
                                <img
                                  width={300}
                                  height={280}
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVpZ3UzdaVypiYyaiaotZflGGWqWlpkWWhqJo"
                                  className="vc_single_image-img attachment-medium"
                                  alt=""
                                  loading="lazy"
                                  srcSet="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVpZ3UzdaVypiYyaiaotZflGGWqWlpkWWhqJo 300w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVpZ3UzdaVypiYyaiaotZfmGeeqW5imGWhqJo 768w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVpZ3UzdaVypiYyaiaotZfkmGYZa9qlmxloqOa 1024w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVpZ3UzdaVypiYyaiaotZgy6HN 2000w"
                                  sizes="(max-width: 300px) 100vw, 300px"
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="wpb_text_column wpb_content_element  vc_custom_1612814289896">
                            <div className="wpb_wrapper">
                              <h4 style={{ textAlign: "center" }}>
                                Chris Karageuzian
                              </h4>
                              <h6 style={{ textAlign: "center" }}>
                                <span style={{ color: "#57bc90" }}>
                                  CEO AND CO-FOUNDER
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-4">
                      <div className="vc_column-inner vc_custom_1615873605672">
                        <div className="wpb_wrapper">
                          <div className="wpb_single_image wpb_content_element vc_align_center">
                            <figure className="wpb_wrapper vc_figure">
                              <a
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqseUpJSfk5bNX8Kj2pqlmsKlZg"
                                target="_self"
                                className="vc_single_image-wrapper   vc_box_border_grey"
                              >
                                <img
                                  width={300}
                                  height={280}
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVrJbFz8Kex5Sb2J2hp49lkWHeY29hj6Gnnw"
                                  className="vc_single_image-img attachment-medium"
                                  alt=""
                                  loading="lazy"
                                  srcSet="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVrJbFz8Kex5Sb2J2hp49lkWHeY29hj6Gnnw 300w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVrJbFz8Kex5Sb2J2hp49pl2neaGhoj6Gnnw 768w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVrJbFz8Kex5Sb2J2hp49jkWOaqXBmlmWhqJo 1024w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ6VZZaVrJbFz8Kex5Sb2J2hp5Cc0Zg 2000w"
                                  sizes="(max-width: 300px) 100vw, 300px"
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="wpb_text_column wpb_content_element  vc_custom_1612814295644">
                            <div className="wpb_wrapper">
                              <h4 style={{ textAlign: "center" }}>
                                Jack Artinian
                              </h4>
                              <h6 style={{ textAlign: "center" }}>
                                <span style={{ color: "#57bc90" }}>
                                  COO AND CO-FOUNDER
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-4">
                      <div className="vc_column-inner vc_custom_1615873612291">
                        <div className="wpb_wrapper">
                          <div className="wpb_single_image wpb_content_element vc_align_center">
                            <figure className="wpb_wrapper vc_figure">
                              <a
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqseUpJSZm6XDoI6qx5-gnMqYpWc"
                                target="_self"
                                className="vc_single_image-wrapper   vc_box_border_grey"
                              >
                                <img
                                  className="vc_single_image-img "
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaV0prU19Kkk2Vol2JlY5Kqk2mWX6GhyA"
                                  width={300}
                                  height={280}
                                  alt="person1212"
                                  title="person1212"
                                />
                              </a>
                            </figure>
                          </div>
                          <div className="wpb_text_column wpb_content_element  vc_custom_1612814301260">
                            <div className="wpb_wrapper">
                              <h4 style={{ textAlign: "center" }}>
                                Diran Yanikian
                              </h4>
                              <h6 style={{ textAlign: "center" }}>
                                <span style={{ color: "#57bc90" }}>CTO</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section className="wpb_row vc_row-fluid vc_custom_1615873669231 vc_row-has-fill">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                <div className="vc_column-inner vc_custom_1612894557299">
                  <div
                    className="wpb_wrapper "
                    style={{ backgroundColor: "#eff1f4" }}
                  >
                    <h1
                      className="vc_custom_1612894670929"
                      style={{ fontSize: "46px", textAlign: "center" }}
                    >
                      {" "}
                      Behind the Name
                    </h1>
                    <div
                      className="vc_empty_space  vc_custom_1612894606875"
                      style={{ height: "32px" }}
                    >
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1612894601955">
                      <div className="wpb_wrapper">
                        <p>
                          <strong>Helpwithmyloan</strong> is direct,
                          streamlined, and focused on you; no empty spaces or
                          unknowns. We launched this financing platform to
                          simplify the process and help you find funding faster.
                          HWML turns a common request into end-to-end support.
                        </p>
                      </div>
                    </div>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1615873674860 vc_row-has-fill">
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            {" "}
                            <div className="text-center mb30">
                              <h1 className="big-title">95%</h1>{" "}
                              <div className="small-title">
                                Loan Funding Rate
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            {" "}
                            <div className="text-center mb30">
                              <h1 className="big-title">$850 Million+</h1>{" "}
                              <div className="small-title">Funded</div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            {" "}
                            <div className="text-center mb30">
                              <h1 className="big-title">300+</h1>{" "}
                              <div className="small-title">
                                Lenders in our Network
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wpb_row vc_row-fluid vc_custom_1615873969536 vc_row-has-fill">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                      className="vc_custom_heading"
                    >
                      Lending Made Easy
                    </h1>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1615874088867 max-850">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          <span style={{ color: "#ffffff" }}>
                            We’re here for you every step of the way. Whether
                            you’re looking for a home mortgage, business loans,
                            stated commercial loans, SBA disaster loan
                            assistance, or something else, we can help.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid textaligncenter vc_custom_1615873892630">
                      <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-offset-3">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1615873793561">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-turquoise"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                                title
                              >
                                APPLY NOW
                              </a>
                            </div>
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1615873806150">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZg"
                                title
                              >
                                FAQ
                              </a>
                            </div>
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1615873817804">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGhq8aYpmDXpZA"
                                title
                              >
                                CONTACT US
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
