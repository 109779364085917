import React from "react";
import "./contact.css";
const Contactpage = () => {
  return (
    <div>
      <div className="heroSection-contact">
        <h4>Contact Us</h4>
        <a
          href="https://ramondent.zipforhome.com/"
          class="cta-btn"
          target="_blank"
        >
          Apply Now
        </a>
      </div>
      <section id="contact">
        <div class="contact-wrapper">
          <form id="contact-form" class="form-horizontal" role="form">
            <div class="form-group">
              <div class="col-sm-12">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="NAME"
                  name="name"
                  value=""
                />
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-12">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="EMAIL"
                  name="email"
                  value=""
                />
              </div>
            </div>
            <div class="col-sm-12">
              <textarea
                class="form-control"
                rows="10"
                placeholder="MESSAGE"
                name="message"
                required
              ></textarea>
            </div>

            <button
              class="btn send-button"
              id="submit"
              type="submit"
              value="SEND"
            >
              <div class="alt-send-button">
                <i class="fa fa-paper-plane"></i>
                <span class="send-text">SEND</span>
              </div>
            </button>
          </form>

          <div class="direct-contact-container">
            <ul class="contact-list">
              <li class="list-item">
                <i class="fa fa-map-marker fa-2x"></i>
                <span
                  class="contact-text place"
                  style={{ lineHeight: "30px", marginTop: "20px" }}
                >
                  5757 W,Century Blvd,Suite 700 Los Angeles,CA 90045
                </span>
              </li>

              <li class="list-item">
                <i class="fa fa-phone fa-2x"></i>
                <span class="contact-text phone">
                  <a href="tel:1-212-555-5555" title="Give me a call">
                    426-675-6295
                  </a>
                </span>
              </li>
              <li class="list-item">
                <i class="fa fa-envelope fa-2x"></i>
                <span class="contact-text gmail">
                  <a href="mailto:info@fettifi.com" title="Send me an email">
                    info@fettifi.com
                  </a>
                </span>
              </li>
            </ul>

            <hr />
            <ul class="social-media-list">
              <li>
                <a href="#" target="_blank" class="contact-icon">
                  <i class="fa fa-github" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" class="contact-icon">
                  <i class="fa fa-codepen" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" class="contact-icon">
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" class="contact-icon">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
            <hr />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactpage;
