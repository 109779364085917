// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #053f4a; /* Button background color */
  color: #fff; /* Button text color */
  width: 50px; /* Button width */
  height: 50px; /* Button height */
  border-radius: 50%; /* Make it round */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20; /* Ensure it appears on top of other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

.draggable {
  /* Add draggable behavior */
  -webkit-app-region: drag;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ChatBtn.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,yBAAyB,EAAE,4BAA4B;EACvD,WAAW,EAAE,sBAAsB;EACnC,WAAW,EAAE,iBAAiB;EAC9B,YAAY,EAAE,kBAAkB;EAChC,kBAAkB,EAAE,kBAAkB;EACtC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,WAAW,EAAE,+CAA+C;EAC5D,wCAAwC,EAAE,wBAAwB;AACpE;;AAEA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B","sourcesContent":[".chatbot-button {\r\n  position: fixed;\r\n  bottom: 20px;\r\n  right: 20px;\r\n  background-color: #053f4a; /* Button background color */\r\n  color: #fff; /* Button text color */\r\n  width: 50px; /* Button width */\r\n  height: 50px; /* Button height */\r\n  border-radius: 50%; /* Make it round */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  cursor: pointer;\r\n  z-index: 20; /* Ensure it appears on top of other elements */\r\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a shadow effect */\r\n}\r\n\r\n.draggable {\r\n  /* Add draggable behavior */\r\n  -webkit-app-region: drag;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
