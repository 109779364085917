import React from 'react'
import Layout from '../Components/Layout'

export const Apply = () => {
  return (
    <Layout>
        <section className="wpb_row vc_row-fluid img-full-width hero vc_custom_1547811853405 vc_row-has-fill vc_row-o-content-middle vc_row-flex"><div className="row"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner "><div className="wpb_wrapper ">
                <h1 className="vc_custom_1549259121331" style={{fontSize: '48px', textAlign: 'center', color: '#ffffff'}}> Pick A Loan</h1>
                <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                  <div className="wpb_wrapper">
                    <div id="home-icon-container" className="container">
                      <div id="home-icons">
                        <div className="home-icon">
                          <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGgpMqnlZzDno6d1ZKlYA">
                            <i className="fa fa-hospital-o" />
                            <h3>Commercial Loan</h3>
                          </a>
                        </div>
                        <div className="home-icon">
                          <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmNemoNOapaaPntCS1GA">
                            <i className="fa fa-briefcase" />
                            <h3>Business Loan</h3>
                          </a>
                        </div>
                        <div className="home-icon">
                          <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqMemoMmaoKfLk81e0qCYn5A">
                            <i className="fa fa-home" />
                            <h3>Residential Loan</h3>
                          </a>
                        </div>
                        <div className="home-icon">
                          <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpselqtSjk5-PntCS1GA">
                            <i className="fa fa-user" />
                            <h3>Personal Loan</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div></div></div></section>
    </Layout>
  )
}
