import React from "react";
import "./ChatBtn.css";
import { useNavigate } from "react-router-dom";

const ChatbotButton = () => {
  const navigate = useNavigate();
  return (
    <div className="chatbot-button draggable" onClick={() => navigate("/chat")}>
      <i class="fa fa-comment-o" aria-hidden="true"></i>
    </div>
  );
};

export default ChatbotButton;
