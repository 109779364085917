import React from "react";
import "./LoanCalculator.css";
import "../../index.css"
import { useNavigate } from "react-router-dom";
import MortgageCalculator from "mortgage-calculator-react";

const LoanCalculator = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="heroSection-terms">
        <h2
          style={{
            fontSize: "44px",
            fontWeight: "bolder",
            textAlign: "center",
            lineHeight: "40px",
          }}
        >
          Loan Calculator
        </h2>

        <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', marginBottom: 50 }}  >
          <MortgageCalculator/>
        </div>

        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    </div>
  );
};

export default LoanCalculator;
