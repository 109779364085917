import React from "react";
import Layout from "../../Components/Layout";

export const CommercialLoan = () => {
  return (
    <Layout>
      <section
        className="wpb_row vc_row-fluid backgroundtop vc_row-has-fill"
        style={{
          backgroundImage: `url("https://t4.ftcdn.net/jpg/04/95/28/65/360_F_495286577_rpsT2Shmr6g81hOhGXALhxWOfx1vOQBa.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1615874147147">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "60px",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    className="vc_custom_heading"
                  >
                    Commercial Loan Form
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row vc_row-fluid vc_custom_1616346053834 vc_row-has-fill">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <div id="cr-form-FORb81a64eb57e544a69c6518b58ddc8393" />
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
