import React from "react";
import "../pages/home/home.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Header/Logo-0122.png";

const Navbar = () => {
  const [mobilenavOpen, setMobileNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
    document.getElementById("drop-link").style.color = "black";
    document.getElementById("drop-arrow").style.color = "black";
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
    document.getElementById("drop-link").style.color = "#0088a9";
    document.getElementById("drop-arrow").style.color = "#0088a9";
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
    document.getElementById("drop-link").style.color = "black";
    document.getElementById("drop-arrow").style.color = "black";
  };

  return (
    <div>
      <header>
        <Link class="logo-text" to="/">
          <img
            src={logo}
            alt=""
            className="navLogoMain"
            // style={{ width: "150px", height: "50px", objectFit: "fill" }}
          />
        </Link>
        <nav>
          <ul class="nav__links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <div
                className="dropdown"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Link id="drop-link">
                  Types of loans
                  <i
                    id="drop-arrow"
                    class="fa fa-caret-down"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </Link>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <Link
                      to="/type/commercial-loan"
                      onClick={closeDropdown}
                      style={{ fontSize: "16px" }}
                    >
                      Commercial Loan
                    </Link>
                    <Link
                      to="/type/business-loan"
                      onClick={closeDropdown}
                      style={{ fontSize: "16px" }}
                    >
                      Business Loan
                    </Link>
                    <Link
                      to="/type/residential-loan"
                      onClick={closeDropdown}
                      style={{ fontSize: "16px" }}
                    >
                      Residential Loan
                    </Link>
                    <Link
                      to="/type/personal-loan"
                      onClick={closeDropdown}
                      style={{ fontSize: "16px" }}
                    >
                      Personal Loan
                    </Link>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </nav>
        <div>
          <a
            href="https://ramondent.zipforhome.com/"
            class="cta-btn"
            target="_blank"
            // rel="noopener noreferrer"
            style={{
              background: "#FFFFFF",
              color: "#044e6d",
              border: "1px solid #044e6d",
              marginRight: "10px",
            }}
          >
            Apply Now
          </a>
          <Link to="/contact" class="cta-btn">
            Contact Us
          </Link>
        </div>

        <p class="hamburger" onClick={() => setMobileNavOpen(true)}>
          <i class="fa fa-bars" aria-hidden="true"></i>
        </p>
      </header>
      {mobilenavOpen ? (
        <div class="overlay">
          <button onClick={() => setMobileNavOpen(false)} class="close">
            &times;
          </button>
          <div class="overlay__content">
            <li>
              <Link to="/" onClick={() => setMobileNavOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <div className="dropdown">
                <div className="dropdown">
                  <Link to="" onClick={() => toggleDropdown()}>
                    Types of loans
                    <i
                      class="fa fa-caret-down"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  </Link>

                  {dropdownOpen && (
                    <div className="dropdown-content">
                      <Link
                        to="/type/commercial-loan"
                        onClick={() => {
                          closeDropdown();
                          setMobileNavOpen(false);
                        }}
                      >
                        Commercial Loan
                      </Link>
                      <Link
                        to="/type/business-loan"
                        onClick={() => {
                          closeDropdown();
                          setMobileNavOpen(false);
                        }}
                      >
                        Business Loan
                      </Link>
                      <Link
                        to="/type/residential-loan"
                        onClick={() => {
                          closeDropdown();
                          setMobileNavOpen(false);
                        }}
                      >
                        Residential Loan
                      </Link>
                      <Link
                        to="/type/personal-loan"
                        onClick={() => {
                          closeDropdown();
                          setMobileNavOpen(false);
                        }}
                      >
                        Personal Loan
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </li>
            <li>
              <Link to="/contact" onClick={() => setMobileNavOpen(false)}>
                Contact
              </Link>
            </li>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
