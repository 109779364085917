export const loanDetails = [
  {
    name: "Business Loan",
    title: "Business Loan",
    text1:
      "Business loans are available to cover startup costs,seasonal support, expansion, and unexpected losses. If you need additional funding for your venture, there are many different loan types to consider. Inventory loans, equipment loans, expansion loans, cash flow loans, and business lines of credit are just a handful of options.",
    text2:
      "The SBA business loans, including the 7(a) and 504 loan programs, make it easier to secure funding with capped interest rates and lenient down payments.",
    text3:
      "Fetti’s Financial Services has a 95% loan approval rate, which is almost double the industry average. We work hard to connect you with the right lender and help you secure funding even faster. Our vetted network includes more than 300 financial partners, so you can access business loans from banks and private investors.",
    text4: "",
    icon: (
      <i
        class="fa fa-credit-card-alt"
        style={{ color: "#14a378", fontSize: "40px" }}
        aria-hidden="true"
      ></i>
    ),
    link: "/type/business-loan",
    types: [
      {
        name: "Term Loan",
        title: "Term Loans",
        text1:
          "A term loan allows a borrower to access a set amount of capital they must repay over time.The total amount plus interest is repaid throughout the term monthly,weekly or daily,depending on the length of your financing",
        text2:
          "Businesses leverage term loans for challenges and opportunities in their business.Wether you are looking to bridge a payment gap,hire new staff for an upcoming project or invest in a new marketing initiative,this type of financing can offer funds that can help you achieve your goals",
        text3:
          "Unlike private equity,debt financing provides capital without an exchange of ownership.You maintain full ownership of your business after securing a term financing.The only risk is the financial ramifications of late payments/total defaults",
        text4: "",
        icon: (
          <i
            class="fa fa-handshake-o"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/term-loan",
      },
      {
        name: "Equipment Financing",
        title: "Equipment Financing",
        text1:
          "Regardless of your industry we can help you obtain the best deals on business equipment financing for virtualy every type of equipment.We work with a wide range of business types including medical,software,manufacturing,commerce,food services and much more.",
        text2:
          "If you are looking for equipment financing to grow your business,offers new products or services or simply get a competive edge,we can help!.Our streamlined equipment financing process will help you secure the capital you nees in as little as 24 hours",
        text3:
          "All of our business equipment financing options are fully customizable to you and your business needs",
        text4: "",
        icon: (
          <i
            class="fa fa-balance-scale"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/equipment-financing-loan",
      },
      {
        name: "SBA Loan",
        title: "SBA Loans",
        text1:
          "SBA loans are one of the most desirable and sought-after types of business loans.Many small business owners apply for SBA loans befor exploring other options.",
        text2:
          "While you can get SBA financing through both financial institutions and online lenders,they aren't taking all of the risk.These loans are guaranteed through SBA,a branch of government dedicated to fostering stronger smaller businesses .",
        text3:
          "Through most lender SBA loans come with one drawback:it can take forever(upt 8 months) to complete the process.Banks thoroughly review loan applications before providing an answer.When you use SBA loan to drive revenue in your business,it can significantly improve cash flow.",
        text4: "",
        icon: (
          <i
            class="fa fa-briefcase"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/sba-loan",
      },
      {
        name: "Subordinate Debt",
        title: "Subordinate Debt",
        text1:
          "Subordinate Debt is second tier debt.The subordinate lender has the second lien position,whereas the senior lender retains the right to the first lien position.",
        text2:
          "The first position has the right to remain whole,meaning that they are entitled to repayment before second tier lender",
        text3: "",
        text4: "",
        icon: (
          <i
            class="fa fa-users"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/subordinate-debt",
      },
      {
        name: "Asset Based Lending",
        title: "Asset Based Lending",
        text1:
          "Asset Based Lending is a type of business financing in which the lender secures the agreement with an asset or colateral.Asset based lending can give the borrower either a loan or credit",
        text2:
          "Collateral for asset based lending does not need to be real estate.Other more liquid assets like receivables,inventory,purchase orders and potentially equipments can also act as collateral",
        text3:
          "Unlike other financing options,your business can qualify for asset based financing with a low credit score or no history.Rather than meeting tradition requirements,you can qualify based on your receivables,inventory or other assets",
        text4: "",
        icon: (
          <i
            class="fa fa-taxi"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/asset-based-lending",
      },
      {
        name: "Revenue Based Financing",
        title: "Revenue Based Financing",
        text1:
          "Revenue best financing isn't your traditional loan instead it's a financing solution that allows your business to secure capital quickly and easy by leveraging your business future sales. ",
        text2:
          "This type of Financing is ideal for when you need cash in the short term and don't want to go through the hurdles of applying for more traditional loan solutions like Business term loans or SBA programs.",
        text3:
          "If your business is profitable revenue based financing can help you take your operation to the next level you can use the funds for a wide range of business expensive including operating cost, growth opportunities and much more.",
        text4: "",
        icon: (
          <i
            class="fa fa-bar-chart"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/revenue-based-financing",
      },
      {
        name: "Business Line of credit",
        title: "Business Line of credit",
        text1:
          "A small business line of credit gives you flexible access to cash on as needed basis. This type of financing allows you to draw cash from your total credit limit for any business purpose",
        text2:
          "With a revolving line of credit more cash will become available as you pay down your balance. Unlike selling equity, the funds from a business line of credit allow you to maintain business ownership, profits and full control",
        text3: "",
        text4: "",
        icon: (
          <i
            class="fa fa-money"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/business-line-credit",
      },
      {
        name: "Small Business Loans",
        title: "Small Business Loans",
        text1:
          "A small business loan provides funding for entrepreneurs to take a challenges take advantages of opportunities and invest in their businesses you can use the funds for almost any business purpose including working capital ,equipment payroll another Investments.",
        text2:
          "Short term business loans are best for short term revenue driving opportunities and challenges that you need to fill up quickly long term business loans on the other hand are best sorted for expenses that want benefit your business immediately life opening a new physical location or purchasing a new business entirely",
        text3: "",
        text4: "",
        icon: (
          <i
            class="fa fa-sitemap"
            style={{ color: "#14a378", fontSize: "40px" }}
            aria-hidden="true"
          ></i>
        ),
        link: "/type/small-business-loan",
      },
    ],
  },
  {
    name: "Commercial Loan",
    title: "Commercial  Loan",
    text1:
      "Open a new storefront, invest in multi-family housing, purchase new equipment, or expand your business with more commercial properties. Commercial loans typically start at $50,000 and extend into the millions. It’s often harder to qualify for these kinds of large business loans because the stakes are so much higher.",
    text2:
      "Commercial loans are typically categorized based on repayment options and your funding goals. Overall, commercial lending is straight-forward with clear labels.",
    text3:
      "For example, short-term loans are typically repaid within 18 months, versus a term loan, which can stretch up to 30 years. Equipment loans can be used to purchase equipment or business assets. Commercial real estate loans are designated for purchasing business property.",
    text4:
      "Fetti’s Financial Services has a 95% loan approval rate, which is almost double the industry average. We work hard to connect you with the right lender and help you secure funding even faster. Our vetted network includes more than 300 financial partners, so you can access SBA loans, conventional commercial loans, and loans from private investors.",
    icon: (
      <i
        class="fa fa-university"
        style={{ color: "#14a378", fontSize: "40px" }}
        aria-hidden="true"
      ></i>
    ),
    link: "/type/commercial-loan",
    types: [],
  },
  {
    name: "Residential Loan",
    title: "Residential  Loan",
    text1:
      "Purchase your new family home or vacation property with a residential loan. Home loans are only allowed for primary residences. There are separate types of loans for commercial real estate and rental properties. Unless you can purchase your home outright with a cash sale, it’s time to connect with a lender and get pre-approved.",
    text2:
      "Fetti’s Financial Services has a 95% loan approval rate, which is almost double the industry average. We work hard to connect you with the right lender and help you secure funding even faster. Our vetted network includes more than 300 financial partners with both big banks and private lenders.",
    text3: "",
    text4: "",
    icon: (
      <i
        class="fa fa-home"
        style={{ color: "#14a378", fontSize: "40px" }}
        aria-hidden="true"
      ></i>
    ),
    link: "/type/residential-loan",
    types: [],
  },
  {
    name: "Personal Loan",
    title: "Personal  Loan",
    text1:
      "Personal loans are often nicknamed ‘signature’ loans because they don’t require collateral – just your signature. Fund your personal projects and pay for discretionary purchases with a consumer loan. If you have good credit, it’s easy to qualify!",
    text2:
      "Finance experts typically recommend using personal loans to fund projects that will increase wealth, including home improvement and debt consolidation. In most cases, personal loans are less expensive than credit cards. Interest rates for personal loans are determined by your credit score, income, and debt-to-income ratio.",
    text3:
      "Fetti’s Financial Services has a 95% loan approval rate, which is almost double the industry average. We work hard to connect you with the right lender and help you secure funding even faster. Our vetted network includes more than 300 financial partners with banks and private investors.",
    text4: "",
    icon: (
      <i
        class="fa fa-address-card"
        style={{ color: "#14a378", fontSize: "40px" }}
        aria-hidden="true"
      ></i>
    ),
    link: "/type/personal-loan",
    types: [],
  },
];
