// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot-container {
  width: 100vw;
  margin: 0 auto;
  background-color: #f3f2f3;
  /* padding: 40px; */
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.chat-msgs-container {
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
  height: 70%;
  overflow-y: scroll;
  width: 90%;
  padding-top: 20px;
  margin: 10px auto;
}

.chat-input-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.chat-input {
  width: 100%; /* Adjusted width to accommodate icon */
  padding-right: 40px; /* Added padding for icon */
  border: 1px solid black;
  border-radius: 10px;
}

.send-icon {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  color: #294b54;
  cursor: pointer;
}
.chat-msgs-container div {
  width: 80%;
}
@media (max-width: 780px) {
  /* Adjustments for even smaller screens */
  .help-content-2 {
    flex-direction: column;
  }
  .chat-msgs-container {
    width: 100vw;
  }
  .chat-msgs-container div {
    width: 96%;
  }
  .chat-input-container {
    width: 90%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/chatbot.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW,EAAE,uCAAuC;EACpD,mBAAmB,EAAE,2BAA2B;EAChD,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,cAAc;EACd,eAAe;AACjB;AACA;EACE,UAAU;AACZ;AACA;EACE,yCAAyC;EACzC;IACE,sBAAsB;EACxB;EACA;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".chatbot-container {\r\n  width: 100vw;\r\n  margin: 0 auto;\r\n  background-color: #f3f2f3;\r\n  /* padding: 40px; */\r\n  position: relative;\r\n  height: 100vh;\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.chat-msgs-container {\r\n  padding-bottom: 20px;\r\n  /* margin-bottom: 20px; */\r\n  height: 70%;\r\n  overflow-y: scroll;\r\n  width: 90%;\r\n  padding-top: 20px;\r\n  margin: 10px auto;\r\n}\r\n\r\n.chat-input-container {\r\n  width: 80%;\r\n  margin: 0 auto;\r\n  position: relative;\r\n}\r\n\r\n.chat-input {\r\n  width: 100%; /* Adjusted width to accommodate icon */\r\n  padding-right: 40px; /* Added padding for icon */\r\n  border: 1px solid black;\r\n  border-radius: 10px;\r\n}\r\n\r\n.send-icon {\r\n  position: absolute;\r\n  top: 50%;\r\n  right: 50px;\r\n  transform: translateY(-50%);\r\n  color: #294b54;\r\n  cursor: pointer;\r\n}\r\n.chat-msgs-container div {\r\n  width: 80%;\r\n}\r\n@media (max-width: 780px) {\r\n  /* Adjustments for even smaller screens */\r\n  .help-content-2 {\r\n    flex-direction: column;\r\n  }\r\n  .chat-msgs-container {\r\n    width: 100vw;\r\n  }\r\n  .chat-msgs-container div {\r\n    width: 96%;\r\n  }\r\n  .chat-input-container {\r\n    width: 90%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
