import React, { useState } from "react";
import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <section class="hero">
        <div class="hero-container">
          <div class="hero-left">
            <h3>
              Creating Financial Solutions Today For The Obstacles Of Tomorrow
            </h3>
            <p>
              At Fetti We Do Money! Just complete our simple application and we
              will find the best loan product for your situation.
            </p>
            <a
              href="https://ramondent.zipforhome.com/"
              class="cta-btn"
              target="_blank"
              style={{ marginBottom: "20px" }}
            >
              Apply Now{" "}
              <i class="fa fa-arrow-right" style={{ fontSize: "16px" }}></i>
            </a>
          </div>
          <div class="hero-right">
            {/* <img src="/heroimg.PNG" alt="" /> */}
            <img src="/herobanner2.jpg" alt="" />
          </div>
        </div>
      </section>
      <section class="type-loan">
        <h2>What type of loan are you looking for?</h2>
        <div class="cards-container">
          <Link to="/type/personal-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-address-card"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Personal</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>
          <Link to="/type/business-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-credit-card-alt"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Business</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>

          <Link to="/type/commercial-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-university"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Commercial</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>

          <Link to="/type/residential-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-home"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Residential</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>
        </div>
        {/* <div class="company-logos">
          <div class="company-div">
            <i class="fa fa-slack" aria-hidden="true"></i>
            <h5>slack</h5>
          </div>
          <div class="company-div">
            <i class="fa fa-apple" aria-hidden="true"></i>
            <h5>apple</h5>
          </div>
          <div class="company-div">
            <i class="fa fa-amazon" aria-hidden="true"></i>
            <h5>amazon</h5>
          </div>
          <div class="company-div">
            <i class="fa fa-google" aria-hidden="true"></i>
            <h5>Google</h5>
          </div>
          <div class="company-div">
            <i class="fa fa-slack" aria-hidden="true"></i>
            <h5>slack</h5>
          </div>
        </div> */}
        <div class="communication">
          <h4> Speed and Efficiency</h4>
          <p>
            We keep you informed about loan status updates every step of the way
          </p>
          <div class="comm-cards">
            <div class="single-card-comm">
              <div>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </div>
              <h6>SMS text</h6>
            </div>
            <div class="single-card-comm">
              <div>
                <i class="fa fa-television" aria-hidden="true"></i>
              </div>
              <h6>Online Portal</h6>
            </div>
            <div class="single-card-comm">
              <div>
                <i class="fa fa-at" aria-hidden="true"></i>
              </div>
              <h6>Email Message</h6>
            </div>
          </div>
        </div>
        <section class="work">
          <div class="work-left">
            <h4>How does our process work</h4>
            <div class="work-list">
              <div class="work-card">
                <div class="work-num">I</div>
                <div class="work-details">
                  <h4>Apply Online</h4>
                </div>
              </div>

              <div class="work-card">
                <div class="work-num">II</div>
                <div class="work-details">
                  <h4>Upload Documents</h4>
                </div>
              </div>
              <div class="work-card">
                <div class="work-num">III</div>
                <div class="work-details">
                  <h4>
                    Communicate with loan specialist to determine best solution
                  </h4>
                </div>
              </div>
              <div class="work-card">
                <div class="work-num">IV</div>
                <div class="work-details">
                  <h4>Complete loan process</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="work-right">
            <img src="/workimg.PNG" alt="" />
          </div>
        </section>
        <section class="help">
          <div class="help-content">
            <h2>Need help or advice?</h2>
            <p>
              Ask one of our agents on this page or give us a call. We are
              always happy to speak with you.
            </p>
            <div class="button-container">
              <button onClick={() => navigate("/chat")}>
                Live chat<i class="fa fa-comment" aria-hidden="true"></i>
              </button>
              <button>Call us 426-675-6295</button>
            </div>
          </div>
          <div class="shapes">
            <div class="square"></div>
          </div>
        </section>
        <h2 style={{ marginTop: "60px" }}>
          Flexible loan programs to meet every need
        </h2>
        <div class="cards-container">
          <Link to="/type/personal-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-address-card"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Personal</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>
          <Link to="/type/business-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-credit-card-alt"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Business</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>

          <Link to="/type/commercial-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-university"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Commercial</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>

          <Link to="/type/residential-loan" class="single-card">
            <div class="card-left">
              <i
                class="fa fa-home"
                style={{ color: "#14a378", fontSize: "28px" }}
                aria-hidden="true"
              ></i>
              <h4>Residential</h4>
            </div>
            <div class="icon-div">
              <i
                class="fa fa-arrow-right"
                style={{ fontSize: "14px" }}
                aria-hidden="true"
              ></i>
            </div>
          </Link>
        </div>
        <section class="help-2">
          <div class="help-content-2">
            <div class="help-content-text">
              <h2>Book a consultation with us</h2>
              <p>
                Simple online experience. Dedicated Professionals. Online
                pre-approvals
              </p>
            </div>
            <div class="input-container">
              <div class="button-container">
                <a
                  href="https://calendly.com/nicoleg-nri/loan-inquiry"
                  target="_blank"
                  id="btn-for-calendly"
                >
                  Book Appointment
                </a>
              </div>
            </div>
          </div>
          <div class="shapes">
            <div class="square"></div>
            <div class="circle"></div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Home;
