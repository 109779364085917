import React from "react";
import "./privacypolicy.css";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const policiesData = [
    {
      title: "Information We Collect",
      content: `
      a. <strong>Personal Information:</strong> We may collect personal information that you voluntarily provide to us when you use the Site, such as your name, email address, phone number, mailing address, and any other information you choose to provide.<br>
      b. <strong>Usage Data:</strong> We may also collect information about how you access and use the Site, including your IP address, browser type, operating system, referring URLs, and other technical information.<br>
      c. <strong>Cookies and Similar Technologies:</strong> We may use cookies, web beacons, and other tracking technologies to collect information about your interactions with the Site and to improve your experience.
    `,
    },
    {
      title: "How We Use Your Information",
      content: `
      a. <strong>Provide and Improve the Site:</strong> We use the information we collect to operate, maintain, and improve the Site, including to personalize your experience and to analyze how the Site is used.<br>
      b. <strong>Communicate with You:</strong> We may use your contact information to send you updates about the Site, respond to your inquiries, and provide customer support.<br>
      c. <strong>Marketing:</strong> With your consent, we may use your contact information to send you promotional materials and other communications about products and services that may be of interest to you.<br>
      d. <strong>Compliance with Law:</strong> We may use your information to comply with applicable laws, regulations, legal processes, or government requests.
    `,
    },
    {
      title: "How We Share Your Information",
      content: `
      a. <strong>Service Providers:</strong> We may share your information with third-party service providers that perform services on our behalf, such as hosting, analytics, and marketing.<br>
      b. <strong>Business Transfers:</strong> If we are involved in a merger, acquisition, financing, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.<br>
      c. <strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to a subpoena or court order.
    `,
    },
    {
      title: "Your Choices",
      content: `
      a. <strong>Opt-Out:</strong> You may opt out of receiving promotional emails from us by following the instructions in those emails. Please note that even if you opt out, we may still send you non-promotional communications, such as those related to your account or transactions.<br>
      b. <strong>Cookies:</strong> You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Site may become inaccessible or not function properly.
    `,
    },
    {
      title: "Data Security",
      content: `
      We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
    `,
    },
    {
      title: "Children's Privacy",
      content: `
      The Site is not intended for children under the age of 18, and we do not knowingly collect personal information from children under the age of 18.
    `,
    },
    {
      title: "Changes to This Privacy Policy",
      content: `
      We may update this Privacy Policy from time to time by posting a new version on the Site. You should check this page periodically for any changes. Your continued use of the Site after any modifications to this Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
    `,
    },
    {
      title: "Contact Us",
      content: `
      If you have any questions about this Privacy Policy, please contact us at Email: info@fettifi.com<br>Office: 424-675-6295.<br>
      
    `,
    },
  ];

  return (
    <div>
      <div className="heroSection-privacy">
        <h2
          style={{
            fontSize: "44px",
            fontWeight: "bolder",
            textAlign: "center",
            lineHeight: "40px",
          }}
        >
          Our Privacy Policy
        </h2>
        <p>
          This Privacy Policy describes how Fetti Financial Services LLC ("we,"
          "us," or "our") collects, uses, and discloses personal information
          when you use our website, including all content, features, and
          functionality available through www.fettifi.com.
        </p>
        {policiesData.map((policy, index) => (
          <div key={index} className="privacySection">
            <h3>{policy.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: policy.content }}></p>
          </div>
        ))}
        <p style={{ fontWeight: "600" }}>
          By using the Site, you consent to our collection, use, and disclosure
          of your information as described in this Privacy Policy. If you do not
          agree to this Privacy Policy, please do not use the Site.
        </p>

        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
