import React from "react";
import Layout from "../../Components/Layout";

export const TypeCommercialLoan = () => {
  return (
    <Layout>
      <section
        className="wpb_row vc_row-fluid backgroundtop vc_row-has-fill"
        style={{
          backgroundImage: `url("https://t4.ftcdn.net/jpg/04/95/28/65/360_F_495286577_rpsT2Shmr6g81hOhGXALhxWOfx1vOQBa.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1615874147147">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "60px",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    className="vc_custom_heading"
                  >
                    Commercial Real Estate Loan
                  </h1>
                </div>

                <div
                  class="vc_btn3-container vc_btn3-center"
                  style={{ marginTop: 50 }}
                >
                  <a
                    class="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                    href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGgpMqnlZzDno6d1ZKlYA"
                    title="Apply For a Loan"
                  >
                    APPLY NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row vc_row-fluid vc_custom_1616023092661 vc_row-has-fill">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "40px",
                      color: "#3c4d6b",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontStyle: "normal",
                    }}
                    className="vc_custom_heading"
                  >
                    Commercial Loans
                  </h1>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p style={{ textAlign: "center" }}>
                        Explore Your Lending Options
                      </p>
                      <p>
                        Open a new storefront, invest in multi-family housing,
                        purchase new equipment, or expand your business with
                        more commercial properties. Commercial loans typically
                        start at $50,000 and extend into the millions. It’s
                        often harder to qualify for these kinds of large
                        business loans because the stakes are so much higher.
                      </p>
                      <p>
                        Commercial loans are typically categorized based on
                        repayment options and your funding goals. Overall,
                        commercial lending is straight-forward with clear
                        labels.
                      </p>
                      <p>
                        For example, short-term loans are typically repaid
                        within 18 months, versus a term loan, which can stretch
                        up to 30 years. Equipment loans can be used to purchase
                        equipment or business assets. Commercial real estate
                        loans are designated for purchasing business property.
                      </p>
                      <p>
                        Fetti’s Financial Services has a 95% loan approval rate, which is
                        almost double the industry average. We work hard to
                        connect you with the right lender and help you secure
                        funding even faster. Our vetted network includes more
                        than 300 financial partners, so you can access SBA
                        loans, conventional commercial loans, and loans from
                        private investors.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="iconlist"
        class="wpb_row vc_row-fluid iconlist vc_custom_1613781334400 vc_row-has-fill"
      >
        <div class="container">
          <div class="row">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "40px",
                      color: "#3c4d6b",
                      textAlign: "center",
                    }}
                    class="vc_custom_heading"
                  >
                    300+ Financial Partners
                  </h1>
                  <div class="vc_row wpb_row vc_inner vc_row-fluid hwml_iconlist_container vc_custom_1575507611511 vc_column-gap-10 vc_row-o-content-middle vc_row-flex">
                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                      <div class="vc_column-inner vc_custom_1575420114931">
                        <div class="wpb_wrapper">
                          <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421358011">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper   vc_box_border_grey">
                                <img
                                  class="vc_single_image-img "
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVxZfVkZRtlqtvmWJjapaqmWWUoaWY"
                                  width="174"
                                  height="84"
                                  alt="cbs-174x84"
                                  title="cbs-174x84"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                      <div class="vc_column-inner vc_custom_1575420165452">
                        <div class="wpb_wrapper">
                          <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421634076">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper   vc_box_border_grey">
                                <img
                                  class="vc_single_image-img "
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVw5fFkZRtlqtvmWJjapaqmWWUoaWY"
                                  width="174"
                                  height="84"
                                  alt="abc-174x84"
                                  title="abc-174x84"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                      <div class="vc_column-inner vc_custom_1575420118763">
                        <div class="wpb_wrapper">
                          <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421376893">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper   vc_box_border_grey">
                                <img
                                  class="vc_single_image-img "
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVyKTakZRjk2pr3W1mYJNplameZWWhz54"
                                  width="174"
                                  height="84"
                                  alt="fox-1-174x84"
                                  title="fox-1-174x84"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                      <div class="vc_column-inner vc_custom_1575420122691">
                        <div class="wpb_wrapper">
                          <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421387838">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper   vc_box_border_grey">
                                <img
                                  class="vc_single_image-img "
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaV0JfFkZRtlqtvmWJjapaqmWWUoaWY"
                                  width="174"
                                  height="84"
                                  alt="nbc-174x84"
                                  title="nbc-174x84"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                      <div class="vc_column-inner vc_custom_1575420126945">
                        <div class="wpb_wrapper">
                          <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421398230">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper   vc_box_border_grey">
                                <img
                                  class="vc_single_image-img "
                                  src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVtqTSlZNmrqKe1GKXZJdpkWWdYmxklmlnbmBsm95smJTSo8k"
                                  width="95"
                                  height="84"
                                  alt="Top100Logo-e1570471535206"
                                  title="Top100Logo-e1570471535206"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-vc-parallax="1.2"
        className="wpb_row vc_row-fluid vc_custom_1616023016711 vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving"
      >
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1613523467074">
                <div className="wpb_wrapper ">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1613523247247 vc_row-has-fill">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner vc_custom_1613523443974">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "40px",
                              color: "#3c4d6b",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Loan Breakdown
                          </h1>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>
                                Use our loan calculator to estimate monthly
                                payments, compare interest rate options, and
                                calculate costs for different terms.
                                Amortization schedules outline the total cost of
                                principal payments and interest over time.
                              </p>
                            </div>
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div className="vc_btn3-container vc_btn3-center">
                            <a
                              className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZKYk5_Fp82S2qCpYA"
                              title
                            >
                              TRY THE CALCULATOR
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="vc_parallax-inner skrollable skrollable-between"
          data-bottom-top="top: -20%;"
          data-top-bottom="top: 0%;"
          style={{ height: "120%", top: "-13.2457%" }}
        />
      </section>

      <section className="wpb_row vc_row-fluid vc_custom_1616023037915 vc_row-has-fill">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "40px",
                      color: "#3c4d6b",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontStyle: "normal",
                    }}
                    className="vc_custom_heading"
                  >
                    Explore More Loan Types
                  </h1>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p style={{ textAlign: "center" }}>
                        <strong>Fetti’s Financial Services</strong> is a full spectrum
                        lending platform. Looking for a different type of
                        funding? We’ll help you search for the best loan and get
                        approved even faster.
                      </p>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_column-gap-30">
                    <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                      <div className="vc_column-inner vc_custom_1613587511959">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-center">
                            <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xl vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span
                                className="vc_icon_element-icon fa fa-home"
                                style={{ color: "#77c9d4 !important" }}
                              />
                            </div>
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <h1
                            style={{
                              fontSize: "28px",
                              color: "#3c4d6b",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Residential Loans
                          </h1>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p style={{ textAlign: "center" }}>
                                <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZSnl6bLlsaf2pqYnY6jppmhYJiTZpWV">
                                  READ MORE
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                      <div className="vc_column-inner vc_custom_1613587528474">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-center">
                            <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xl vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span
                                className="vc_icon_element-icon fa fa-briefcase"
                                style={{ color: "#77c9d4 !important" }}
                              />
                            </div>
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <h1
                            style={{
                              fontSize: "28px",
                              color: "#3c4d6b",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Business Loans
                          </h1>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p style={{ textAlign: "center" }}>
                                <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZSXp6bLoMak2V6joMKlZGpgZZeV">
                                  READ MORE
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                      <div className="vc_column-inner vc_custom_1613587545788">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-center">
                            <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xl vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span
                                className="vc_icon_element-icon fa fa-user"
                                style={{ color: "#77c9d4 !important" }}
                              />
                            </div>
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <h1
                            style={{
                              fontSize: "28px",
                              color: "#3c4d6b",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Personal Loans
                          </h1>
                          <div
                            className="vc_empty_space"
                            style={{ height: "32px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p style={{ textAlign: "center" }}>
                                <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZSll6XVoc-S0l6joMKlZGpgZZeV">
                                  READ MORE
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpb_row vc_row-fluid vc_custom_1616022711865 vc_row-has-fill" style={{ backgroundColor: "#002752" }}>
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "40px",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                    className="vc_custom_heading"
                  >
                    Still Have Questions? Let’s Talk.
                  </h1>
                  <div className="wpb_text_column wpb_content_element  vc_custom_1616022838545 max-850">
                    <div className="wpb_wrapper">
                      <p style={{ textAlign: "center" }}>
                        <span style={{ color: "#ffffff" }}>
                          We’re eager to help you find the right lending options
                          to meet your needs. Connect with a real person for
                          one-on-one loan advice and support. Or start by
                          filling out our fast, free questionnaire to apply for
                          a loan and match up with our finance partners.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid textaligncenter vc_custom_1616022852447">
                    <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-offset-3">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_btn3-container vc_btn3-inline vc_custom_1616022737726">
                            <a
                              className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-turquoise"
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                              title
                            >
                              APPLY NOW
                            </a>
                          </div>
                          <div className="vc_btn3-container vc_btn3-inline vc_custom_1616022745787">
                            <a
                              className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZg"
                              title
                            >
                              FAQ
                            </a>
                          </div>
                          <div className="vc_btn3-container vc_btn3-inline vc_custom_1616022956385">
                            <a
                              className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGhq8aYpmDXpZA"
                              title
                            >
                              CONTACT US
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
