import React from "react";
import "./LoanTypeLayout.css";
import { loanDetails } from "../loansDetail";
import { Link } from "react-router-dom";

const LayoutForTypeOfLoan = ({ loanType }) => {
  // loanDetails;
  const selectedLoan = loanDetails.find((loan) => loan.name === loanType);

  if (!selectedLoan) {
    // Handle the case where the loan type is not found
    return <div>No loan details found for the selected type.</div>;
  }

  const loanTitle = selectedLoan.title;
  const text1 = selectedLoan.text1;
  const text2 = selectedLoan.text2;
  const text3 = selectedLoan.text3;
  const text4 = selectedLoan.text4;
  const otherLoans = loanDetails.filter((loan) => loan.name !== loanType);
  return (
    <div>
      <div className="heroSection">
        <h4>{loanTitle}</h4>
        <a href="https://ramondent.zipforhome.com/" target="_blank">
          Apply Now
        </a>
      </div>
      <div className="loanDetailsContainer">
        <h4>Explore your lending options</h4>
        <p>{text1}</p>
        <p>{text2}</p>
        <p>{text3}</p>
        <p>{text4}</p>
        {selectedLoan.types.length > 0 && <h4>Types of {selectedLoan.name}</h4>}
        {selectedLoan.types.length > 0 && (
          <div className="single-card-other-container">
            {selectedLoan.types.map((loan) => (
              <Link
                to={loan.link}
                key={loan.name}
                className="single-card-other"
              >
                <h4>{loan.name}</h4>

                <div style={{ marginTop: "20px" }}>{loan.icon}</div>
              </Link>
            ))}
          </div>
        )}
      </div>

      <div className="loan-breakdown">
        <div className="breakdown-details">
          <h3>Loan Breakdown</h3>
          <p>
            Use our loan calculator to estimate monthly payments, compare
            interest rate options, and calculate costs for different terms.
            Amortization schedules outline the total cost of principal payments
            and interest over time.
          </p>
          <button>TRY THE CALCULATOR</button>
        </div>
      </div>
      <div className="explain-more-loans">
        <h4>Explore More Loan Types</h4>
        <p>
          Fetti’s Financial Services is a full spectrum lending platform.
          Looking for a different type of funding? We’ll help you search for the
          best loan and get approved even faster.
        </p>

        <div className="single-card-other-container">
          {otherLoans.map((loan) => (
            <Link to={loan.link} key={loan.name} className="single-card-other">
              <h4>{loan.name}</h4>

              <div style={{ marginTop: "20px" }}>{loan.icon}</div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LayoutForTypeOfLoan;
