import React from "react";
import "./../pages/home/home.css";
import { Link } from "react-router-dom";
import logo from "../assets/Header/Logo-0122.png";

const Footer2 = () => {
  return (
    <footer>
      <div class="container">
        <div class="row">
          <img src={logo} alt="" className="footerLogoMain" />
          <div class="col" id="company">
            <h3 class="logo">Fetti Financial Services LLC</h3>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "8px",
                textAlign: "left",
              }}
            >
              We Do Money!
            </p>
            {/* <div class="social">
              <a href="#" class="icon-footer-div">
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="#" class="icon-footer-div">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
              <a href="#" class="icon-footer-div">
                <i class="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </div> */}
          </div>

          <div class="col">
            <h3>Resources</h3>
            <div class="links">
              <a href="/">Get a quote</a>
              <a href="https://ramondent.zipforhome.com/" target="_blank">
                Apply now
              </a>
              <Link to="/loan-calculator">Loan calculator</Link>
            </div>
          </div>

          <div class="col">
            <h3>Contact</h3>
            <div class="contact-details">
              <i class="fa fa-map-marker"></i>
              <p style={{ width: "300px" }}>
                5757 W, Century Blvd, Suite 700 Los Angeles, CA 90045
              </p>
            </div>
            <div class="contact-details">
              <i class="fa fa-phone"></i>
              <p>426-675-6295</p>
            </div>
            <div class="contact-details" style={{ marginTop: "2px" }}>
              <i class="fa fa-envelope"></i>
              <a
                style={{ fontSize: "16px", color: "#254953", marginTop: "2px" }}
                href="mailto:info@fettifi.com"
                title="Send me an email"
              >
                info@fettifi.com
              </a>
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <div class="links-in-row">
            <Link to="/terms-of-use">Terms of use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/service-level-agreement">Service Level Agreement</Link>
          </div>
          <p style={{ fontSize: "14px", color: "rgb(117, 117, 117)" }}>
            © 2024, Fetti Financial Services, LLC. NMLS #2267023. NMLS Consumer
            Access. Program rates and parameters are subject to change without
            notice. Intended for use by real estate and lending professionals
            only and not for distribution. Market snapshot © theFinancials.com.
            All market rates quoted are generic market rates taken from
            thefinancials.com reprinted with permission. All Rights Reserved.
            NMLS #2267023.CA# 60DBO-153798 This website is for professional use
            only and is a business to business communication not intended for
            consumers.Privacy Policy (2)
          </p>
        </div>

        {/* <div style={{ marginTop: "30px" }}>
          <p
            style={{
              fontSize: "12px",
              color: "rgb(117, 117, 117)",
              marginTop: 10,
            }}
          >
            Designed by realz solutions <br />{" "}
            <a href="https://realzsolutions.com/" target="_blank">
              https://realzsolutions.com/
            </a>
          </p>
        </div> */}
        
      </div>
    </footer>
  );
};

export default Footer2;
