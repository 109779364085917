import React from "react";
import Layout from "../Components/Layout";

export const Contact = () => {
  return (
    <Layout>
      <section
        data-vc-parallax="1.2"
        className="wpb_row vc_row-fluid backgroundtop vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving"
        style={{
          backgroundImage: `url("https://t4.ftcdn.net/jpg/04/95/28/65/360_F_495286577_rpsT2Shmr6g81hOhGXALhxWOfx1vOQBa.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1615874998817">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "60px",
                      color: "#ffffff",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontStyle: "normal",
                    }}
                    className="vc_custom_heading"
                  >
                    Contact Us
                  </h1>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_btn3-container vc_btn3-center">
                    <a
                      className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                      href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpdhk"
                      title="Apply For a Loan"
                    >
                      APPLY NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="vc_parallax-inner skrollable skrollable-between"
          data-bottom-top="top: -20%;"
          data-top-bottom="top: 0%;"
          style={{ height: "120%", top: "-12.8848%" }}
        />
      </section>

      <section className="wpb_row vc_row-fluid vc_custom_1615875401878">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-6">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p className="p1">
                        There are so many ways to get in touch, and we’ll always
                        do our best to get back to you within 24 hours.
                      </p>
                      <p className="p1">
                        Whether you have questions about the application
                        process, suggestions, or need advice about loan options,
                        our team is here to help!
                      </p>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <table
                                style={{
                                  borderCollapse: "collapse",
                                  width: "387px",
                                  height: "165px",
                                }}
                                border={0}
                                width={174}
                                cellSpacing={0}
                                cellPadding={0}
                              >
                                <tbody>
                                  <tr style={{ height: "16.0pt" }}>
                                    <td
                                      className="xl65"
                                      style={{
                                        height: "16.0pt",
                                        width: "45pt",
                                      }}
                                      width={87}
                                      height={21}
                                    >
                                      <strong>Email:</strong>
                                    </td>
                                    <td style={{ width: "45pt" }} width={87}>
                                      <a href="mailto:info@helpwithmyloan.com">
                                        info@helpwithmyloan.com
                                      </a>
                                    </td>
                                  </tr>
                                  <tr style={{ height: "16.0pt" }}>
                                    <td
                                      className="xl64"
                                      style={{ height: "16.0pt" }}
                                      height={21}
                                    >
                                      <strong>Visit:</strong>
                                    </td>
                                    <td>
                                      177 East Colorado Blvd.
                                      <br />
                                      Suite 200
                                      <br />
                                      Pasadena, CA 91105
                                    </td>
                                  </tr>
                                  <tr style={{ height: "16.0pt" }}>
                                    <td
                                      className="xl64"
                                      style={{ height: "16.0pt" }}
                                      height={21}
                                    >
                                      <strong>Hours:</strong>
                                    </td>
                                    <td>
                                      Monday through Friday
                                      <br />9 AM – 5 PM
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_raw_code wpb_content_element wpb_raw_html vc_custom_1664406705451">
                    <div className="wpb_wrapper">
                      <div className="widget-social contact-social mt40">
                        <ul className="listnone">
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWorqiPnZibmJXV1Z-SydGikazIotKKoNmdf6yuocKflw"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fa fa-facebook" />
                              <span>Facebook</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWorqiPoKWrp5TN2JXRlMWkz5PLm86jrs6pmqDbntCS1GA"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fa fa-instagram" />
                              <span>Instagram</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWorqiPo6CmnpjKz6KSydGikcfSo9KUpd5kmpjOotia2pmkqs2mmKY"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fa fa-linkedin" />
                              <span>LinkedIn</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWorqiPq6Cjp6LRlJfT05F1ysnPptmcq82iq5_Rk88"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fab fa-tiktok" />
                              <span>TikTok</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWlrprVq5yqYZbV02PMy86l2c3Xns-so9SWoGQ"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fa fa-twitter" />
                              <span>Twitter</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWorqiPsKatp6jIy2LH1c9kxczEpNCYo5SKdYXKh9CY35d4gqKqgIKUmrGaqJzYo3Y"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fa fa-youtube" />
                              <span>YouTube</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWSpZTJpqlmmaCV2WOdmJWXmcnFbpGjpsmYk6bWYdOk2Q"
                              target="_blank"
                              rel="noopener"
                            >
                              <i className="fa fa-rss" />
                              <span>RSS</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n    .fontawesomesvg {width: 1em;\n      height: 1em;\n      vertical-align: -.125em;\n    }\n  ",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-6">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <div
                    className="gf_browser_chrome gform_wrapper form-group_wrapper gform_legacy_markup_wrapper"
                    id="gform_wrapper_11"
                  >
                    <form
                      method="post"
                      encType="multipart/form-data"
                      id="gform_11"
                      className="form-group gform_legacy_markup"
                      action="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGhq8aYpmDXpZA"
                    >
                      <div className="gform_body gform-body">
                        <ul
                          id="gform_fields_11"
                          className="gform_fields top_label form_sublabel_below description_below"
                        >
                          <li
                            id="field_11_5"
                            className="gfield req-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                          >
                            <label
                              className="gfield_label"
                              htmlFor="input_11_5"
                            >
                              Your Name
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_text">
                              <input
                                name="input_5"
                                id="input_11_5"
                                type="text"
                                defaultValue
                                className="medium"
                                tabIndex={300}
                                placeholder="Your Name*"
                                aria-required="true"
                                aria-invalid="false"
                              />{" "}
                            </div>
                          </li>
                          <li
                            id="field_11_2"
                            className="gfield req-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                          >
                            <label
                              className="gfield_label"
                              htmlFor="input_11_2"
                            >
                              Your Number
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_phone">
                              <input
                                name="input_2"
                                id="input_11_2"
                                type="text"
                                defaultValue
                                className="medium"
                                tabIndex={301}
                                placeholder="Your Number*"
                                aria-required="true"
                                aria-invalid="false"
                              />
                            </div>
                          </li>
                          <li
                            id="field_11_3"
                            className="gfield req-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                          >
                            <label
                              className="gfield_label"
                              htmlFor="input_11_3"
                            >
                              Your Email
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_email">
                              <input
                                name="input_3"
                                id="input_11_3"
                                type="text"
                                defaultValue
                                className="medium"
                                tabIndex={302}
                                placeholder="Your Email*"
                                aria-required="true"
                                aria-invalid="false"
                              />
                            </div>
                          </li>
                          <li
                            id="field_11_4"
                            className="gfield req-field gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                          >
                            <label
                              className="gfield_label"
                              htmlFor="input_11_4"
                            >
                              Your Message
                              <span className="gfield_required">
                                <span className="gfield_required gfield_required_asterisk">
                                  *
                                </span>
                              </span>
                            </label>
                            <div className="ginput_container ginput_container_textarea">
                              <textarea
                                name="input_4"
                                id="input_11_4"
                                className="textarea large"
                                tabIndex={303}
                                placeholder="Your Message*"
                                aria-required="true"
                                aria-invalid="false"
                                rows={10}
                                cols={50}
                                defaultValue={""}
                              />
                            </div>
                          </li>
                          <li
                            id="field_11_7"
                            className="gfield field_sublabel_below field_description_below gfield_visibility_visible"
                          >
                            <label
                              className="gfield_label"
                              htmlFor="input_11_7"
                            >
                              CAPTCHA
                            </label>
                            <div
                              id="input_11_7"
                              className="ginput_container ginput_recaptcha gform-initialized"
                              data-sitekey="6Lc5Q4wUAAAAAOVKxQQBScInKE2aSa_1HoGAEp_D"
                              data-theme="light"
                              data-tabindex={304}
                              data-badge
                            >
                              <div style={{ width: "304px", height: "78px" }}>
                                <div>
                                  <iframe
                                    title="reCAPTCHA"
                                    width={304}
                                    height={78}
                                    role="presentation"
                                    name="a-am7nuko7aqbe"
                                    frameBorder={0}
                                    scrolling="no"
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6Lc5Q4wUAAAAAOVKxQQBScInKE2aSa_1HoGAEp_D&co=aHR0cHM6Ly9pbnN0YW50dW5ibG9jay5jb206NDQz&hl=en&v=Hq4JZivTyQ7GP8Kt571Tzodj&theme=light&size=normal&cb=b5dyzemob3eh"
                                    tabIndex={304}
                                  />
                                </div>
                                <textarea
                                  id="g-recaptcha-response"
                                  name="g-recaptcha-response"
                                  className="g-recaptcha-response"
                                  style={{
                                    width: "250px",
                                    height: "40px",
                                    border: "1px solid rgb(193, 193, 193)",
                                    margin: "10px 25px",
                                    padding: "0px",
                                    resize: "none",
                                    display: "none",
                                  }}
                                  defaultValue={""}
                                />
                              </div>
                              <iframe style={{ display: "none" }} />
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="gform_footer top_label">
                        {" "}
                        <input
                          type="submit"
                          id="gform_submit_button_11"
                          className="gform_button button"
                          defaultValue="Submit"
                          tabIndex={305}
                          onclick='if(window["gf_submitting_11"]){return false;}  window["gf_submitting_11"]=true;  '
                          onkeypress='if( event.keyCode == 13 ){ if(window["gf_submitting_11"]){return false;} window["gf_submitting_11"]=true;  jQuery("#gform_11").trigger("submit",[true]); }'
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="is_submit_11"
                          defaultValue={1}
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_submit"
                          defaultValue={11}
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_unique_id"
                          defaultValue
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="state_11"
                          defaultValue="WyJbXSIsIjZlMmNmNGQ3NmY5MTkwY2FlMzE5N2VmOTg2MzczZDYyIl0="
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_target_page_number_11"
                          id="gform_target_page_number_11"
                          defaultValue={0}
                        />
                        <input
                          type="hidden"
                          className="gform_hidden"
                          name="gform_source_page_number_11"
                          id="gform_source_page_number_11"
                          defaultValue={1}
                        />
                        <input
                          type="hidden"
                          name="gform_field_values"
                          defaultValue
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
