import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/Header/Logo.png";

export const Header = () => {
  const location = useLocation();
  return (
    <>
      <div class="top-bar">
        <div class="container">
          <div class="row"></div>
        </div>
      </div>

      <div class="header-2">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-12 col-xs-12 logo-col">
              <div class="logo">
                <Link to={"/"}>
                  <img src={Logo} alt="" />
                </Link>
              </div>
            </div>

            <div class="col-md-9 text-right hidden-xs">
              <div class="header-action">
                {/* <Link to={"/loan-calculator"} class="btn btn-primary">
                  Loan Calculator
                </Link>{" "} */}
                {location.pathname !== "/apply" && (
                  <Link to={"https://ramondent.zipforhome.com"} target="_blank" class="btn btn-default">
                    Apply
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="navigation-2">
          <div class="container d-flex align-items-center justify-content-center">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div id="navigation">
                  <div id="menu-button"></div>
                  <ul id="menu-header-menu" class="menu">
                    {/* <li
                      id="menu-item-1822"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1822 has-sub"
                    >
                      <span className="submenu-button"></span>
                      <Link
                        to="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                        className="animsition-link"
                      >
                        Apply for a Loan
                      </Link>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-1740"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1740"
                        >
                          <Link
                            to={"/commercial-loan"}
                            className="animsition-link"
                          >
                            Commercial Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-1737"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1737"
                        >
                          <Link to="/business-loan" className="animsition-link">
                            Business Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-1738"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1738"
                        >
                          <Link
                            to="/residential-loan"
                            className="animsition-link"
                          >
                            Residential Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-1739"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1739"
                        >
                          <Link to="/personal-loan" className="animsition-link">
                            Personal Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-2167"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2167"
                        >
                          <Link to="/eidl-loan" className="animsition-link">
                            EIDL Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-3100"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3100"
                        >
                          <Link
                            to="https://instantunblock.com/index.php?q=naan0qWbYJViqqXEmKehp5TSzJ3Sx9CYy8XPZMWipJShomLHpMRg0JKanMJm"
                            className="animsition-link"
                          >
                            Employee Retention Credit
                          </Link>
                        </li>
                        <li
                          id="menu-item-2175"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2175"
                        >
                          <Link
                            to="/loan-calculator"
                            className="animsition-link"
                          >
                            Loan Calculator
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    {/* <li
                      id="menu-item-3099"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3099"
                    >
                      <Link
                        to="https://instantunblock.com/index.php?q=naan0qWbYJWonJPKpZiqYZvd06CSx9KlkQ"
                        className="animsition-link"
                      >
                        Webinar
                      </Link>
                    </li> */}
                    <li
                      id="menu-item-2726"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2726 has-sub"
                    >
                      <span className="submenu-button"></span>
                      <Link
                        to="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqtujnNhioZmPntCS1KRkY5A"
                        className="animsition-link"
                      >
                        Types of Loans
                      </Link>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-2731"
                          className="menu-item menu-item-type-post_type menu-item-object-loan menu-item-2731"
                        >
                          <Link
                            to="/type/commercial-loan"
                            className="animsition-link"
                          >
                            Commercial Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-2729"
                          className="menu-item menu-item-type-post_type menu-item-object-loan menu-item-2729"
                        >
                          <Link
                            to="/type/business-loan"
                            className="animsition-link"
                          >
                            Business Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-2730"
                          className="menu-item menu-item-type-post_type menu-item-object-loan menu-item-2730"
                        >
                          <Link
                            to="/type/residential-loan"
                            className="animsition-link"
                          >
                            Residential Loan
                          </Link>
                        </li>
                        <li
                          id="menu-item-2728"
                          className="menu-item menu-item-type-post_type menu-item-object-loan menu-item-2728"
                        >
                          <Link
                            to="/type/personal-loan"
                            className="animsition-link"
                          >
                            Personal Loan
                          </Link>
                        </li>
                        {/* <li
                          id="menu-item-2727"
                          className="menu-item menu-item-type-post_type menu-item-object-loan menu-item-2727"
                        >
                          <Link
                            to="/type/eidl-loan"
                            className="animsition-link"
                          >
                            EIDL
                          </Link>
                        </li> */}
                      </ul>
                    </li>
                    {/* <li
                      id="menu-item-2725"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2725"
                    >
                      <Link to="/howitworks" className="animsition-link">
                        How It Works
                      </Link>
                    </li> */}

                    {/* <li
                      id="menu-item-2724"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2724 has-sub"
                    >
                      <span className="submenu-button"></span>
                      <Link
                        to="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSl8SirNlip6aPZJA"
                        className="animsition-link"
                      >
                        About
                      </Link>
                      <ul className="sub-menu">
                        <li
                          id="menu-item-2853"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2853"
                        >
                          <Link to="/abouthwml" className="animsition-link">
                            About HWML
                          </Link>
                        </li>
                        <li
                          id="menu-item-1147"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1147"
                        >
                          <Link to="/faq" className="animsition-link">
                            FAQ
                          </Link>
                        </li>
                        <li
                          id="menu-item-1427"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1427"
                        >
                          <Link
                            to="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmM6inpQ"
                            className="animsition-link"
                          >
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </li> */}

                    {/* <li
                      id="menu-item-2854"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2854"
                    >
                      <Link
                        to="https://instantunblock.com/index.php?q=naan0qWbYJWZrp7NZZioo2I"
                        className="animsition-link"
                      >
                        For Lenders / Brokers
                      </Link>
                    </li> */}
                    
                    <li
                      id="menu-item-1278"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1278"
                    >
                      <Link to="/contact" className="animsition-link">
                        Contact
                      </Link>
                    </li>
                  </ul>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
