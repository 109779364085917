// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._3gGF3MGRKxzQZh6RczOEoz {
  display: table-row;
  background-color: white !important;
  color: black;
}

.ggtReN8zzGuVkr5-qrqOl {
  background-color: var(--secondary-two) !important;
  color: white;
  border-bottom: none;
}

._2QxqScDSDuK4P0ny7KZKNr {
  text-align: center;
  font-size: 9pt;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,iDAAiD;EACjD,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n._3gGF3MGRKxzQZh6RczOEoz {\n  display: table-row;\n  background-color: white !important;\n  color: black;\n}\n\n.ggtReN8zzGuVkr5-qrqOl {\n  background-color: var(--secondary-two) !important;\n  color: white;\n  border-bottom: none;\n}\n\n._2QxqScDSDuK4P0ny7KZKNr {\n  text-align: center;\n  font-size: 9pt;\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
