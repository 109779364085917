import React from "react";
import "../App.css";
import Layout from "../Components/Layout";

export const Dashboard = () => {
  return (
    <>
      <Layout>
        {/* Section 1 */}
        <section className="wpb_row vc_row-fluid img-full-width vc_row-has-fill vc_row-o-content-middle vc_row-flex" style={{ backgroundImage: `url("https://t3.ftcdn.net/jpg/04/83/68/84/240_F_483688481_mLT0el5ouPPPNo3H6gCzqseK5JQo7qyj.jpg")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1613759283227">
                <div className="wpb_wrapper ">
                  <h1
                    className="vc_custom_1607543251236"
                    style={{
                      fontSize: "48px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {" "}
                    Our Goal Is To Help You Reach Yours.
                  </h1>
                  <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                    <div className="wpb_wrapper">
                      <div id="home-icon-container" className="container">
                        <div id="home-icons">
                          <div className="home-icon">
                            <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGgpMqnlZzDno6d1ZKlYA">
                              <i className="fa fa-hospital-o" />
                              <h3>Commercial Loan</h3>
                            </a>
                          </div>
                          <div className="home-icon">
                            <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmNemoNOapaaPntCS1GA">
                              <i className="fa fa-briefcase" />
                              <h3>Business Loan</h3>
                            </a>
                          </div>
                          <div className="home-icon">
                            <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqMemoMmaoKfLk81e0qCYn5A">
                              <i className="fa fa-home" />
                              <h3>Residential Loan</h3>
                            </a>
                          </div>
                          <div className="home-icon">
                            <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpselqtSjk5-PntCS1GA">
                              <i className="fa fa-user" />
                              <h3>Personal Loan</h3>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section className="wpb_row vc_row-fluid vc_custom_1613775025199 vc_row-has-fill vc_column-gap-15">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-6">
              <div className="vc_column-inner vc_custom_1613705629912">
                <div className="wpb_wrapper ">
                  <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1615796408469">
                    <figure className="wpb_wrapper vc_figure">
                      <div className="vc_single_image-wrapper   vc_box_border_grey">
                        <img
                          width={640}
                          height={427}
                          src="https://t3.ftcdn.net/jpg/03/30/09/98/240_F_330099838_43eEyvA61UQ6ijDcA3uuQgEjiBihvzJp.jpg"
                          className="vc_single_image-img attachment-large"
                          alt=""
                          loading="lazy"
                          // srcSet="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaVkmaPlZNolqttnWhgndKZ 1024w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaVkmaPl5Nm2mVnlWOco8k 300w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaVkmaPm5lu2mhol2Oco8k 768w, https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZZeVZJaVkmaQztOd 1500w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-6">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p>
                      Fetti’s Financial Services is a full spectrum lending platform. By
                        filling out our simple questionnaire we allow you to
                        connect with 300+ banks and alternative lenders
                        competing for your loan.
                      </p>
                      <ul>
                        <li>
                          <strong>95% loan funding rate</strong>
                        </li>
                        <li>
                          <strong>No spam or telemarketing calls</strong>
                        </li>
                        <li>
                          <strong>Will not affect your credit score</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_btn3-container vc_btn3-left">
                    <a
                      className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-turquoise"
                      href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSqtujnNhioZmPntCS1KRm"
                      title
                    >
                      Discover Your Loan Options
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 3 */}
        <section className="wpb_row vc_row-fluid vc_custom_1615795991264 vc_row-has-fill">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#3c4d6b",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontStyle: "normal",
                      }}
                      className="vc_custom_heading"
                    >
                      End-to-End Support
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid">
                      <div className="wpb_column vc_column_container vc_col-sm-2">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper" />
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-8">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p style={{ textAlign: "center" }}>
                                  From fragmented to fast tracked – we’re here
                                  to improve the industry. Fetti’s Financial Services was
                                  founded by two high-level bankers with insight
                                  and expertise to streamline the process.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-2">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper" />
                        </div>
                      </div>
                    </div>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#3c4d6b",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontStyle: "italic",
                      }}
                      className="vc_custom_heading"
                    >
                      I'm a...
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_column-gap-30 vc_row-o-equal-height vc_row-flex">
                      <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                        <div className="vc_column-inner vc_custom_1613587511959">
                          <div className="wpb_wrapper">
                            <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                              <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xl vc_icon_element-style- vc_icon_element-background-color-grey">
                                <span
                                  className="vc_icon_element-icon fa fa-home"
                                  style={{ color: "#77c9d4 !important" }}
                                />
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <h1
                              style={{
                                fontSize: "28px",
                                color: "#3c4d6b",
                                textAlign: "left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontStyle: "normal",
                              }}
                              className="vc_custom_heading"
                            >
                              Homeowner or Buyer
                            </h1>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p>
                                  Refinance your mortgage, compare current
                                  residential loan rates, and get approved for
                                  your first home loan.
                                </p>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p>
                                  <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZSnl6bLlsaf2pqYnY6pnJmfYMvZqMXax2Q">
                                    READ MORE
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                        <div className="vc_column-inner vc_custom_1613587528474">
                          <div className="wpb_wrapper">
                            <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                              <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xl vc_icon_element-style- vc_icon_element-background-color-grey">
                                <span
                                  className="vc_icon_element-icon fa fa-briefcase"
                                  style={{ color: "#77c9d4 !important" }}
                                />
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <h1
                              style={{
                                fontSize: "28px",
                                color: "#3c4d6b",
                                textAlign: "left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontStyle: "normal",
                              }}
                              className="vc_custom_heading"
                            >
                              Small Business Owner
                            </h1>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p>
                                  Apply for SBA 7(a) loans, open a small
                                  business line of credit, compare business
                                  loans, and more.
                                </p>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p>
                                  <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZSXp6bLoMak2V6joMKlZg">
                                    READ MORE
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                        <div className="vc_column-inner vc_custom_1613587545788">
                          <div className="wpb_wrapper">
                            <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                              <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xl vc_icon_element-style- vc_icon_element-background-color-grey">
                                <span
                                  className="vc_icon_element-icon fa fa-hospital-o"
                                  style={{ color: "#77c9d4 !important" }}
                                />
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <h1
                              style={{
                                fontSize: "28px",
                                color: "#3c4d6b",
                                textAlign: "left",
                                fontFamily: "Roboto",
                                fontWeight: 500,
                                fontStyle: "normal",
                              }}
                              className="vc_custom_heading"
                            >
                              Real Estate Investor
                            </h1>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p>
                                  Secure commercial real estate financing,
                                  including multi-family loans and apartment
                                  lending, and more.
                                </p>
                              </div>
                            </div>
                            <div
                              className="vc_empty_space"
                              style={{ height: "32px" }}
                            >
                              <span className="vc_empty_space_inner" />
                            </div>
                            <div className="wpb_text_column wpb_content_element ">
                              <div className="wpb_wrapper">
                                <p>
                                  <a href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSotGUpZSYoaDPl9OUz5KjXtOcmKRgmNnaldjLkQ">
                                    READ MORE
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 4 */}
        <section className="wpb_row vc_row-fluid vc_custom_1615796798817 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-top vc_row-flex">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
              <div className="vc_column-inner" style={{ backgroundImage: `url("https://t3.ftcdn.net/jpg/02/48/68/84/240_F_248688454_khXDTGRp8jf88St2AiYISihiJ6aXCavm.jpg")`, backgroundSize: 'cover' }}>
                <div className="wpb_wrapper ">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1615796889710 vc_row-has-fill">
                    <div className="wpb_column vc_column_container vc_col-sm-12">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              color: "#3c4d6b",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Get Funding in 5
                          </h1>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p style={{ textAlign: "center" }}>
                                How it Works
                              </p>
                            </div>
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: "20px" }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                          <div className="vc_btn3-container vc_btn3-center">
                            <a
                              className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                              href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSntGqZM6pX6rRpMyklQ"
                              title="How It Works – 2/21"
                            >
                              LEARN MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-6">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-top vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-2">
                      <div className="vc_column-inner vc_custom_1618086665143">
                        <div className="wpb_wrapper">
                          <h2
                            style={{
                              fontSize: "44px",
                              color: "#57bc90",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading leftmobile"
                          >
                            1.
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-10">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "40px",
                              textAlign: "left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Select Your Loan Type
                          </h1>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p style={{ textAlign: "left" }}>
                                Full spectrum lending options
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1617215826538 vc_row-o-content-top vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-2">
                      <div className="vc_column-inner vc_custom_1618086677781">
                        <div className="wpb_wrapper">
                          <h2
                            style={{
                              fontSize: "44px",
                              color: "#57bc90",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading leftmobile"
                          >
                            2.
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-10">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "40px",
                              textAlign: "left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Fill in the Details
                          </h1>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>All-in-one fast, free application</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1618086682285 vc_row-o-content-top vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-2">
                      <div className="vc_column-inner vc_custom_1618086687207">
                        <div className="wpb_wrapper">
                          <h2
                            style={{
                              fontSize: "44px",
                              color: "#57bc90",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading leftmobile"
                          >
                            3.
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-10">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "40px",
                              textAlign: "left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            We'll Match You Up
                          </h1>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>Access 300+ lending partners</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1618086691022 vc_row-o-content-top vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-2">
                      <div className="vc_column-inner vc_custom_1618086708038">
                        <div className="wpb_wrapper">
                          <h2
                            style={{
                              fontSize: "44px",
                              color: "#57bc90",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading leftmobile"
                          >
                            4.
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-10">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "40px",
                              textAlign: "left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Connect with an Expert
                          </h1>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>Compare rates and crunch numbers</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1618086715585 vc_row-o-content-top vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-2">
                      <div className="vc_column-inner vc_custom_1618086712404">
                        <div className="wpb_wrapper">
                          <h2
                            style={{
                              fontSize: "44px",
                              color: "#57bc90",
                              textAlign: "center",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading leftmobile"
                          >
                            5.
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-10">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "40px",
                              textAlign: "left",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                            className="vc_custom_heading"
                          >
                            Take the Next Step
                          </h1>
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <div className="page" title="Page 1">
                                <div className="section">
                                  <div className="layoutArea">
                                    <div className="column">
                                      <p>Personal support through closing</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 5 */}
        <section class="wpb_row vc_row-fluid vc_custom_1613781349100 vc_row-has-fill">
          <div class="container">
            <div class="row">
              <div class="wpb_column vc_column_container vc_col-sm-4">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper ">
                    {" "}
                    <div class="text-center mb30">
                      <h1 class="big-title">95%</h1>{" "}
                      <div class="small-title">Loan Funding Rate</div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div class="wpb_column vc_column_container vc_col-sm-4">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper ">
                    {" "}
                    <div class="text-center mb30">
                      <h1 class="big-title">$850 Million+</h1>{" "}
                      <div class="small-title">Funded</div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div class="wpb_column vc_column_container vc_col-sm-4">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper ">
                    {" "}
                    <div class="text-center mb30">
                      <h1 class="big-title">300+</h1>{" "}
                      <div class="small-title">Lenders in our Network</div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 6 */}
        <section className="wpb_row vc_row-fluid vc_custom_1618087568782 vc_row-has-fill" style={{ backgroundImage: `url("https://t3.ftcdn.net/jpg/04/37/52/52/240_F_437525287_00A3ZzOTcDDIq1sd7IvzT7Cw8YavpG1f.jpg")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-5">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#ffffff",
                        textAlign: "left",
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontStyle: "normal",
                      }}
                      className="vc_custom_heading"
                    >
                      Connecting Key Players
                    </h1>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h5>
                          <span style={{ color: "#ffffff" }}>About Us</span>
                        </h5>
                        <p>
                          <span style={{ color: "#ffffff" }}>
                            Chris Karageuzian and Jack Artinian co-founded
                            Fetti’s Financial Services. They built our platform from the
                            ground up in 2017, and it is quickly growing to
                            serve individuals and business owners across the US.
                            Our simple questionnaire and automated system take a
                            more direct approach to secure loans with more
                            options, fewer delays, and no spam.
                          </span>
                        </p>
                        <p>
                          <span style={{ color: "#ffffff" }}>
                            The two met while working as trailblazing VPs in the
                            banking world, and then set out to create a better
                            system. With insider experience and banking
                            expertise, Chris and Jack don’t only know what to
                            fix – they know how.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="vc_empty_space" style={{ height: "32px" }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="vc_btn3-container vc_btn3-left">
                      <a
                        className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-outline vc_btn3-color-white"
                        href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSl8SirNlip6aR"
                        title="About Us – 2/21"
                      >
                        LEARN MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-7">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper " />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 6+ */}
        <section className="wpb_row vc_row-fluid vc_custom_1613780923572 vc_row-has-fill">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-7">
              <div className="vc_column-inner vc_custom_1615797069434">
                <div className="wpb_wrapper ">
                  <h1
                    style={{
                      fontSize: "40px",
                      color: "#3c4d6b",
                      textAlign: "left",
                    }}
                    className="vc_custom_heading"
                  >
                    Economic Relief Hub
                  </h1>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p>Find and Compare Financing Options</p>
                      <p>
                        We’re here for you. Secure funding even faster and stay
                        afloat with the best financial resources. We’ll match
                        you with 300+ lenders in our network, so you can compare
                        rates and loan options.
                      </p>
                      <p>
                        Spend less time in the underwriting process and get the
                        most out of a single loan application.
                      </p>
                    </div>
                  </div>
                  <div className="vc_empty_space" style={{ height: "32px" }}>
                    <span className="vc_empty_space_inner" />
                  </div>
                  <div className="vc_btn3-container vc_btn3-left">
                    <a
                      className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                      href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                      title
                    >
                      APPLY NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container vc_col-sm-5">
              <div className="vc_column-inner ">
                <div className="wpb_wrapper ">
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            SBA 7(a) Loans
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            EIDL Loans
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            Disaster Loan Assistance
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            Working Capital Loans
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            Small Business Line of Credit
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            Paycheck Protection Program Loans
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vc_row wpb_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex">
                    <div className="wpb_column vc_column_container vc_col-sm-1">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left">
                            <div className="vc_icon_element-inner vc_icon_element-color-vista_blue vc_icon_element-size-md vc_icon_element-style- vc_icon_element-background-color-grey">
                              <span className="vc_icon_element-icon fa fa-check-circle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wpb_column vc_column_container vc_col-sm-11">
                      <div className="vc_column-inner">
                        <div className="wpb_wrapper">
                          <h1
                            style={{
                              fontSize: "24px",
                              color: "#3c4d6b",
                              textAlign: "left",
                            }}
                            className="vc_custom_heading"
                          >
                            And More!
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 7 */}
        <section className="wpb_row vc_row-fluid vc_custom_1615797142920">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-8 vc_col-lg-offset-2 vc_col-md-offset-2 vc_col-sm-offset-2">
                <div className="vc_column-inner vc_custom_1482996304005">
                  <div className="wpb_wrapper ">
                    <h1
                      className
                      style={{ fontSize: "40px", textAlign: "center" }}
                    >
                      {" "}
                      Industry Insights
                    </h1>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          Read the newest posts on our blog:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <div className="vc_grid-container-wrapper vc_clearfix">
                      <div
                        className="vc_grid-container vc_clearfix wpb_content_element vc_basic_grid blog-carousel"
                        data-initial-loading-animation="fadeIn"
                        data-vc-grid-settings='{"page_id":2485,"style":"pagination","action":"vc_get_vc_grid_data","shortcode_id":"1698696206155-4ea90d0e-6971-1","items_per_page":"3","auto_play":false,"gap":30,"speed":-1000,"loop":"","animation_in":"","animation_out":"","arrows_design":"none","arrows_color":"blue","arrows_position":"inside","paging_design":"radio_dots","paging_color":"grey","tag":"vc_basic_grid"}'
                        data-vc-request="https://fetti.com/wp-admin/admin-ajax.php"
                        data-vc-post-id={2485}
                        data-vc-public-nonce="2a3c6c7493"
                      >
                        <div className="vc_grid-loading" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 8 */}

        <section
          id="iconlist"
          class="wpb_row vc_row-fluid iconlist vc_custom_1613781334400 vc_row-has-fill"
        >
          <div class="container">
            <div class="row">
              <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner ">
                  <div class="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#3c4d6b",
                        textAlign: "center",
                      }}
                      class="vc_custom_heading"
                    >
                      Featured In
                    </h1>
                    <div class="vc_row wpb_row vc_inner vc_row-fluid hwml_iconlist_container vc_custom_1575507611511 vc_column-gap-10 vc_row-o-content-middle vc_row-flex">
                      <div class="wpb_column vc_column_container vc_col-sm-1/5">
                        <div class="vc_column-inner vc_custom_1575420114931">
                          <div class="wpb_wrapper">
                            <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421358011">
                              <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img
                                    class="vc_single_image-img "
                                    src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVxZfVkZRtlqtvmWJjapaqmWWUoaWY"
                                    width="174"
                                    height="84"
                                    alt="cbs-174x84"
                                    title="cbs-174x84"
                                  />
                                </div>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wpb_column vc_column_container vc_col-sm-1/5">
                        <div class="vc_column-inner vc_custom_1575420165452">
                          <div class="wpb_wrapper">
                            <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421634076">
                              <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img
                                    class="vc_single_image-img "
                                    src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVw5fFkZRtlqtvmWJjapaqmWWUoaWY"
                                    width="174"
                                    height="84"
                                    alt="abc-174x84"
                                    title="abc-174x84"
                                  />
                                </div>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wpb_column vc_column_container vc_col-sm-1/5">
                        <div class="vc_column-inner vc_custom_1575420118763">
                          <div class="wpb_wrapper">
                            <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421376893">
                              <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img
                                    class="vc_single_image-img "
                                    src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVyKTakZRjk2pr3W1mYJNplameZWWhz54"
                                    width="174"
                                    height="84"
                                    alt="fox-1-174x84"
                                    title="fox-1-174x84"
                                  />
                                </div>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wpb_column vc_column_container vc_col-sm-1/5">
                        <div class="vc_column-inner vc_custom_1575420122691">
                          <div class="wpb_wrapper">
                            <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421387838">
                              <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img
                                    class="vc_single_image-img "
                                    src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaV0JfFkZRtlqtvmWJjapaqmWWUoaWY"
                                    width="174"
                                    height="84"
                                    alt="nbc-174x84"
                                    title="nbc-174x84"
                                  />
                                </div>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wpb_column vc_column_container vc_col-sm-1/5">
                        <div class="vc_column-inner vc_custom_1575420126945">
                          <div class="wpb_wrapper">
                            <div class="wpb_single_image wpb_content_element vc_align_center  vc_custom_1575421398230">
                              <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper   vc_box_border_grey">
                                  <img
                                    class="vc_single_image-img "
                                    src="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSrdJgmtSjppjQppCm1p2mksWqZmpjZJ-VZZaVtqTSlZNmrqKe1GKXZJdpkWWdYmxklmlnbmBsm95smJTSo8k"
                                    width="95"
                                    height="84"
                                    alt="Top100Logo-e1570471535206"
                                    title="Top100Logo-e1570471535206"
                                  />
                                </div>
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section 9 */}
        <section className="wpb_row vc_row-fluid vc_custom_1615797518471 vc_row-has-fill">
          <div className="container">
            <div className="row">
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner ">
                  <div className="wpb_wrapper ">
                    <h1
                      style={{
                        fontSize: "40px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                      className="vc_custom_heading"
                    >
                      Time is Money. Let’s Not Waste It.
                    </h1>
                    <div className="wpb_text_column wpb_content_element  vc_custom_1615961085490 max-850">
                      <div className="wpb_wrapper">
                        <p style={{ textAlign: "center" }}>
                          <span style={{ color: "#ffffff" }}>
                            We only collect detailed information for the loan
                            request plus your contact information, so you’ll
                            only receive the loan information that you’re
                            looking for – no spam or telemarketing calls.
                          </span>
                        </p>
                        <p style={{ textAlign: "center" }}>
                          <span style={{ color: "#ffffff" }}>
                            Explore your options, get expert advice, and match
                            with a lender in 24 to 48 hours. Then, select the
                            best option and fund your loan in as little as 10
                            days.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid textaligncenter vc_custom_1615797795061">
                      <div className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-offset-3">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1615797837021">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-turquoise"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSpsuWopKWX5_Rk89g"
                                title
                              >
                                APPLY NOW
                              </a>
                            </div>
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1615797841806">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-white"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSnMOkZg"
                                title
                              >
                                FAQ
                              </a>
                            </div>
                            <div className="vc_btn3-container vc_btn3-inline vc_custom_1615797846266">
                              <a
                                className="vc_general vc_btn3 vc_btn3-size-lg vc_btn3-shape-square vc_btn3-style-flat vc_btn3-color-vista-blue"
                                href="https://instantunblock.com/index.php?q=naan0qWbYJWZnJ3RrqCsm6Df0qPF1JCY0dGSmdGhq8aYpmDXpZA"
                                title
                              >
                                CONTACT US
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
