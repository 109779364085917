import React from "react";
import "./service.css";
import { useNavigate } from "react-router-dom";

const ServiceLevelAgreement = () => {
  const navigate = useNavigate();
  const serviceData = [
    {
      title: "1. Availability",
      content: `
      a. <strong>Uptime:</strong> We strive to ensure that the Site is available 99.9% of the time, excluding scheduled maintenance.<br>
      b. <strong>Downtime:</strong> In the event of unscheduled downtime exceeding 0.1% of the time during any monthly billing cycle, we will provide a service credit as outlined in Section 4.
    `,
    },
    {
      title: "2. Performance",
      content: `
      a. <strong>Response Time:</strong> We aim to maintain a fast response time for the Site, with pages loading within 3 seconds under normal circumstances.<br>
      b. <strong>Scalability:</strong> We will monitor Site performance and scale resources as necessary to accommodate increased traffic and usage.
    `,
    },
    {
      title: "3. Security",
      content: `
      a. <strong>Data Protection:</strong> We implement appropriate security measures to protect user data from unauthorized access, use, or disclosure.<br>
      b. <strong>Compliance:</strong> We adhere to industry standards and regulations governing data security and privacy, including but not limited to GDPR and CCPA.
    `,
    },
    {
      title: "4. Service Credits",
      content: `
      a. <strong>Eligibility:</strong> To be eligible for service credits, you must report downtime to us within 24 hours of occurrence.<br>
      b. <strong>Calculation:</strong> Service credits will be calculated as a percentage of the monthly service fees, based on the duration of downtime exceeding 0.1% of the time during the billing cycle.<br>
      c. <strong>Maximum Credit:</strong> The maximum service credit available in any billing cycle is 10% of the monthly service fees.
    `,
    },
    {
      title: "5. Customer Support",
      content: `
      a. <strong>Availability:</strong> We provide customer support via email and phone during business hours (Monday to Friday, 9:00 AM to 5:00 PM PST).<br>
      b. <strong>Response Time:</strong> We aim to respond to support inquiries within 24 hours of receipt.
    `,
    },
    {
      title: "6. Maintenance",
      content: `
      a. <strong>Scheduled Maintenance:</strong> We will provide advance notice of scheduled maintenance that may result in downtime.<br>
      b. <strong>Emergency Maintenance:</strong> In the event of emergency maintenance, we will make reasonable efforts to minimize downtime and provide notice to affected users.
    `,
    },
    {
      title: "7. Termination",
      content: `
      a. <strong>Termination by User:</strong> You may terminate this agreement at any time by ceasing to use the Site.<br>
      b. <strong>Termination by Us:</strong> We may terminate this agreement for any reason with 30 days' notice.
    `,
    },
    {
      title: "8. Limitation of Liability",
      content: `
      UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SITE.
    `,
    },
    {
      title: "9. Governing Law",
      content: `
      This SLA shall be governed by and construed in accordance with the laws of California, without regard to its conflict of law principles.
    `,
    },
    {
      title: "10. Modifications",
      content: `
      We reserve the right to modify or update this SLA at any time by posting the revised SLA on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the revised SLA.
    `,
    },
  ];

  return (
    <div>
      <div className="heroSection-sla">
        <h2
          style={{
            fontSize: "44px",
            fontWeight: "bolder",
            textAlign: "center",
            lineHeight: "40px",
          }}
        >
          Service Level Agreement
        </h2>
        <p>
          This Service Level Agreement ("SLA") outlines the terms and conditions
          governing the provision of services by Fetti Financial Services LLC
          ("we," "us," or "our") to users of our website, including all content,
          features, and functionality available through www.fettifi.com.
        </p>
        <div className="sla-container">
          {serviceData.map((item, index) => (
            <div key={index} className="slaSection">
              <h3>{item.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          ))}
        </div>
        <p style={{ fontWeight: "600" }}>
          By using the Site, you acknowledge that you have read, understood, and
          agree to be bound by this SLA. If you do not agree to all the terms
          and conditions of this SLA, then you may not use the Site.
        </p>
        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    </div>
  );
};

export default ServiceLevelAgreement;
