import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Apply } from "./pages/Apply";
import { CommercialLoan } from "./pages/ApplyForLoan/CommercialLoan";
import { ResidentialLoan } from "./pages/ApplyForLoan/ResidentialLoan";
import { PersonalLoan } from "./pages/ApplyForLoan/PersonalLoan";
import { EIDLLoan } from "./pages/ApplyForLoan/EIDLLoan";
import { TypeCommercialLoan } from "./pages/TypesOfLoans/TypeCommercialLoan";
import { AboutHWML } from "./pages/About/AboutHWML";
import { HowItWorks } from "./pages/HowItWorks/HowItWorks";
import { Contact } from "./pages/Contact";
import { FAQ } from "./pages/About/FAQ";
import { TypeBusinessLoan } from "./pages/TypesOfLoans/TypeBusinessLoan";
import { TypeResidentialLoan } from "./pages/TypesOfLoans/TypeResidentialLoan";
import { TypePersonalLoan } from "./pages/TypesOfLoans/TypePersonalLoan";
import { TypeEidlLoan } from "./pages/TypesOfLoans/TypeEidlLoan";
import "./App.css";
import Home from "./pages/home/Home";
import Navbar from "./Components/Navbar";
import LayoutForTypeOfLoan from "./Components/LayoutForTypeOfLoan";
import ScrollTop from "./ScrollTop";
import Footer from "./Components/Footer";
import Contactpage from "./pages/Contactpage";
import TypeOfBusinessLoansLayout from "./Components/TypeOfBusinessLoansLayout";
import Chatbot from "./pages/Chatbot";
import ChatbotButton from "./Components/ChatbotButton";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/termsofuse/TermsOfUse";
import ServiceLevelAgreement from "./pages/service level agreement/ServiceLevelAgreement";
import LoanCalculator from "./pages/LoanCalculator/LoanCalculator";

export default function App() {
  return (
    <Router>
      <Navbar />
      <ChatbotButton />
      <ScrollTop />
      <div>
        <Routes>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/chat" element={<Chatbot />} />
          <Route path="/loan-calculator" element={<LoanCalculator />} />
          <Route path="/apply" element={<Apply />} />
          {/* ApplyForLoan */}
          <Route path="/commercial-loan" element={<CommercialLoan />} />
          <Route path="/residential-loan" element={<ResidentialLoan />} />
          <Route path="/personal-loan" element={<PersonalLoan />} />
          <Route path="/eidl-loan" element={<EIDLLoan />} />
          {/* <Route
            path="/type/commercial-loan"
            element={<TypeCommercialLoan />}
          /> */}
          {/* Type of Loans */}
          {/* <Route
            path="/type/commercial-loan"
            element={<TypeCommercialLoan />}
          />
          <Route path="/type/business-loan" element={<TypeBusinessLoan />} />
          <Route
            path="/type/residential-loan"
            element={<TypeResidentialLoan />}
          />
          <Route path="/type/personal-loan" element={<TypePersonalLoan />} /> */}
          {/* { } */}
          <Route
            path="/type/commercial-loan"
            element={
              <LayoutForTypeOfLoan
                loanType="Commercial Loan"
                loanDetails="Details for Commercial Loan..."
              />
            }
          />
          <Route
            path="/type/business-loan"
            element={
              <LayoutForTypeOfLoan
                loanType="Business Loan"
                loanDetails="Details for Business Loan..."
              />
            }
          />
          <Route
            path="/type/residential-loan"
            element={
              <LayoutForTypeOfLoan
                loanType="Residential Loan"
                loanDetails="Details for Residential Loan..."
              />
            }
          />
          <Route
            path="/type/personal-loan"
            element={
              <LayoutForTypeOfLoan
                loanType="Personal Loan"
                loanDetails="Details for Personal Loan..."
              />
            }
          />
          <Route
            path="/type/term-loan"
            element={<TypeOfBusinessLoansLayout loanType="Term Loan" />}
          />
          <Route
            path="/type/equipment-financing-loan"
            element={
              <TypeOfBusinessLoansLayout loanType="Equipment Financing" />
            }
          />{" "}
          <Route
            path="/type/sba-loan"
            element={<TypeOfBusinessLoansLayout loanType="SBA Loan" />}
          />{" "}
          <Route
            path="/type/subordinate-debt"
            element={<TypeOfBusinessLoansLayout loanType="Subordinate Debt" />}
          />
          <Route
            path="/type/asset-based-lending"
            element={
              <TypeOfBusinessLoansLayout loanType="Asset Based Lending" />
            }
          />
          <Route
            path="/type/revenue-based-financing"
            element={
              <TypeOfBusinessLoansLayout loanType="Revenue Based Financing" />
            }
          />
          <Route
            path="/type/small-business-loan"
            element={
              <TypeOfBusinessLoansLayout loanType="Small Business Loans" />
            }
          />
          <Route
            path="/type/business-line-credit"
            element={
              <TypeOfBusinessLoansLayout loanType="Business Line of credit" />
            }
          />
          <Route path="/type/eidl-loan" element={<TypeEidlLoan />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          {/* About */}
          <Route path="/abouthwml" element={<AboutHWML />} />
          <Route path="/faq" element={<FAQ />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path="/contact" element={<Contactpage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route
            path="/service-level-agreement"
            element={<ServiceLevelAgreement />}
          />
          <Route
            path="/loan-calculator"
            element={<LoanCalculator />}
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}
